import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Navbar from "components/Navbar.js";

import Conference from "./news/Conference";
import Rabbi from "./news/Rabbi";
import Diplomacy from "./news/Diplomacy";
import Envoy from "./news/Envoy";
import Foreign from "./news/Foreign";

import Footer from "components/Footer.js";
import { news } from "./content";
import Sponsor from "components/Sponsor.js";
import { sponsorsPage } from "./content";

function NewsSingle(props) {
  const News = props.match.params.news;
  const n = news.find((x) => x.link === News);
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="INDUSTRY NEWS" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container fluid>
          <Row>
            <Col
              lg={9}
              xs={12}
              style={{
                padding: 0,
              }}
            >
              {n.id === "Conference" ? <Conference news={n} /> : ""}
              {n.id === "Rabbi" ? <Rabbi news={n} /> : ""}
              {n.id === "Diplomacy" ? <Diplomacy news={n} /> : ""}
              {n.id === "Envoy" ? <Envoy news={n} /> : ""}
              {n.id === "Foreign" ? <Foreign news={n} /> : ""}
            </Col>
            <Col lg={3} xs={12} style={{ backgroundColor: "#1d1960" }}>
              <h2 className="text-center text-white text-uppercase pt-4">
                <b>Popular Posts</b>
              </h2>
              <br />
              <Container fluid>
                <Row>
                  {news.map((n, index) => (
                    <Col
                      xs={12}
                      style={{
                        padding: 0,
                      }}
                      key={index}
                    >
                      <Card
                        style={{
                          borderRadius: 0,
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        <a href={`/media/industry-news/${n.link}`}>
                          <Container fluid>
                            <Row>
                              <Col xs="6" style={{ padding: 0 }}>
                                <img
                                  alt=""
                                  className="img-rounded img-no-padding img-responsive"
                                  src={require(`assets/news/${n.image}`)}
                                  style={{
                                    borderRadius: 0,
                                    padding: "10px",
                                  }}
                                  height="100%"
                                />
                              </Col>
                              <Col xs="6">
                                <h6
                                  className="text-left text-dark"
                                  style={{ marginTop: "8px", padding: 0 }}
                                >
                                  {n.title}
                                </h6>
                              </Col>
                            </Row>
                          </Container>
                        </a>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default NewsSingle;
