import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhySponsor() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space border-top border-bottom py-3"
        data-parallax={true}
      >
        <Container fluid>
          <Row>
            <Col lg={6} className="text-center">
              <img
                src={require(`assets/images/sponsor.jpg`)}
                width="100%"
                alt=""
                style={{ maxWidth: "600px" }}
              />
            </Col>
            <Col lg={6} className="align-self-center">
              <h1 className="text-700">
                CONNECT AND ENGAGE WITH THE AI COMMUNITY
              </h1>
              <br />
              <h3 className="text-400">
                In its third consecutive year, Artelligence Forum provides an
                opportunity to maximise your brand exposure, position yourself
                as a thought-leader and generate leads with our community.
                <br />
                Sponsorship offers both online and offline promotional
                activities for our niche target audience.
              </h3>
              <Button
                href="/sponsor/sponsorship-enquiry"
                className=""
                color="primary"
                size="sm"
              >
                SPONSOR ENQUIRY
              </Button>{" "}
              <Button
                href="/sponsor/download-report"
                className=""
                color="primary"
                size="sm"
              >
                DOWNLOAD POST-SHOW REPORT
              </Button>{" "}
              <Button
                href="/sponsor/download-brochure"
                className=""
                color="primary"
                size="sm"
              >
                DOWNLOAD BROCHURE
              </Button>
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-3"
          fluid
          style={{
            backgroundColor: "rgb(234,208,241)",
            backgroundImage:
              "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
          }}
        >
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-main">
                SPONSORSHIP OPPORTUNITIES
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            {content.map((data, index) => (
              <Col lg={6} xs={12} key={index} className="py-5 text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <div
                    className="p-2"
                    style={{
                      background:
                        "linear-gradient(90deg,rgba(228, 0, 67, 1) 0%,rgba(110, 40, 130, 1) 35%)",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <h2 className="text-700 text-white text-uppercase m-0">
                      <img
                        src={require(`assets/images/icons/${data.image}`)}
                        style={{ maxWidth: "50px" }}
                        alt="dataimage"
                      />{" "}
                      {data.title}
                    </h2>
                  </div>
                  <h3 className="text-400 pt-2">{data.description}</h3>
                </ScrollAnimation>
              </Col>
            ))}
            <Col lg={12} className={"text-center"}>
              <Button
                href="/sponsor/sponsorship-enquiry"
                className="mt-4"
                color="primary"
                size="md"
              >
                REQUEST A CALLBACK
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "rgb(234,208,241)",
          backgroundImage:
            "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        }}
        className="py-2"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className=" text-center text-bold p-2 text-main">
                THE POWER OF ONLINE PRESENCE
              </h1>
            </Col>
            {stats.map((c, i) => (
              <Col lg={3} xs={3} key={i} className="text-center pt-2 text-dark">
                <h1
                  className="text-700 d-inline mt-0"
                  style={{ fontSize: size ? "3rem" : "2rem" }}
                >
                  {c.value}
                  {c.plus && "+"}
                </h1>
                <h1
                  className="text-400 mt-0"
                  style={{ fontSize: size ? "1.5rem" : "1rem" }}
                >
                  {c.title}
                </h1>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    title: "LEAD THE CONVERSATION",
    image: "16.png",
    description:
      "Host a keynote talk, engage in panel discussions or deliver workshops to a targeted audience from key primary industries including banking and financial services, retail, manufacturing and more.",
  },
  {
    title: "EXTENSIVE MEDIA EXPOSURE",
    image: "15.png",
    description:
      "Expand your social, digital and print footprint via UAE’s leading English daily, Khaleej Times, as we cover the buzz of Artelligence Forum 2020. Work with our editorial team to produce exclusive opinion pieces and position your brand as a thought leader.",
  },
  {
    title: "PREMIUM NETWORKING OPPORTUNITIES",
    image: "14.png",
    description:
      "Connect with the who’s who in the industry and create meaningful connections and partnerships via 1-1 virtual meetings, chats and roundtable discussions in between sessions at our elite Networking Lounge.",
  },
  {
    title: "BRANDING ENTITLEMENTS",
    image: "13.png",
    description:
      "Increase your brand awareness to a wider audience, through branding opportunities within the virtual environment, inclusion on our website, speaker and delegate collateral, AI blogs, and through email communications for the event.",
  },
];

const stats = [
  { title: "Monthly Page Views", value: "30000", plus: true },
  { title: "Newsletter Subscribers", value: "250000", plus: true },
  { title: "Million Hours of Content", value: "3.5 ", plus: true },
];

export default WhySponsor;
