export const speakers = [
  {
    name: "His Excellency Dr. Thani Bin Ahmed Al Zeyoudi",
    title: "Minister of State for Foreign Trade",
    company: "Ministry of Economy, UAE",
    image: "thani.jpg",
    text: [
      `H.E Dr. Thani bin Ahmed Al Zeyoudi, was appointed as Minister of State for Foreign Trade in July 2020 to oversee
    the development of non-oil foreign trade and the expansion of global trade partnerships and increase Exports.
    In addition to supervising the portfolio of retaining and attracting the best talents and competencies serving the
    strategic orientation of the UAE, His Excellency also leads the efforts to enhance the UAE’s investment climate in
    order to achieve the country’s economic interests and strategic goals, thereby strengthening its position as a
    prime destination for business and investments at both regional and global levels.`,
      `
    Prior to his appointment as the Minister of State for Foreign Trade, H.E held the position of Minister of Climate
    Change and Environment and supervised the implementation of the country’s strategies at the domestic level,
    for environment and climate action, agricultural development, animal husbandry and fisheries, in addition to
    combating desertification and preserving biodiversity leading to enhance the sustainability of natural systems,
    food diversity and the transition to a green economy.`,
      `
    rough diverse Global participations, HE led the UAE’s efforts to mitigate and adapt to the repercussions of
    climate change, and protect the country's natural ecosystem by developing and implementing a set of eective
    policies and initiatives to achieve sustainable development at both regional and international levels and by
    strengthening existing and new partnerships with the government and private sectors that are capable of
    increasing the contribution of environment, agricultural, livestock and fisheries sectors to ensuring food security
    and biosafety in the country as well as increasing the quality of national production.`,
      `
    Previously and after he served as the Director of the Department of Energy and Climate Change at the Ministry
    of Foreign Affairs and International Cooperation, H.E Al Zeyoudi was appointed as the UAE’s Permanent
    Representative to the International Renewable Energy Agency (IRENA) as extraordinary Ambassador.
    Minister Al Zeyoudi started his career as an oil engineer at the Abu Dhabi Marine Operating Company
    (ADMA-OPCO), and then moved to Abu Dhabi Future Energy Company (Masdar), where he worked on the
    development of renewable and clean energy technologies and solutions, and also played a key role in the success
    of the UAE’s 2009 campaign to host the headquarters of the International Renewable Energy Agency (IRENA) in
    the capital City, Abu Dhabi.`,
      `
    In 2020, HE. was chosen by the Davos World Economic Forum to become a member of the Young Global
    Leadership Council affliated with the Forum, recognizing his outstanding international efforts in the field of
    environment and climate. In 2015, Dr. Al Zeyoudi received the first award for Excellence from the Cooperation
    Council for the Arab States of the Gulf in recognition of his pioneering efforts in the field of renewable energy.
    H.E Dr. Thani Bin Ahmed Al Zeyoudi holds a Bachelor's degree in Petroleum Engineering from the University of
    Tulsa, an MBA from the New York Institute of Technology, an MBA in Project Management from the British
    University in Dubai, and a PhD in Strategy, Program and Project Management from Skema Business School in
    France.`,
    ],
  },
  {
    name: "His Excellency Dr. Tariq Bin Hendi",
    title: "Director General",
    company: "Abu Dhabi Investment Office",
    image: "tariq.jpg",
    text: [
      `H.E. Dr. Tariq Bin Hendi is the Director General of the Abu Dhabi Investment Office (ADIO).
    Serving as the Director General and CEO, ADIO has been His Excellency’s professional home since
    October 2019.<br/>
    Under his leadership, ADIO has launched international offices around the world with the first office
    being in Tel Aviv, Israel. Devoting his career to progress, bold ideas and teamwork, His Excellency
    has positioned ADIO to always seek new ideas and challenges in order to promote Abu Dhabi
    globally.<br/>
    Prior to joining ADIO, His Excellency held leadership roles at various institutions, including Emirates
    NBD, Mubadala and Citibank. He also sits on a number of boards, including AXA, GCIC and Emirates
    Post Group.<br/>
    His Excellency earned his stripes in professional finance at Citi Bank in London. With a joint MBA
    from LBS and Columbia, he then joined Mubadala in the UAE sovereign wealth sector.
    His Excellency holds a PhD in Economics from Imperial College, London, as well as graduate
    degrees from Columbia University and London Business School.`,
    ],
  },
  {
    name: " His Excellency Pavan Kapoor",
    title: "",
    company: "Indian Ambassador to the UAE",
    image: "pavan.jpg",
    text: [
      `In a diplomatic career spanning over three decades, Ambassador Kapoor has
    served in different capacities in the Indian Missions in Moscow, Kyiv, London and
    Geneva apart from serving in the Ministry of External Affairs and the Prime
    Minister’s Office in New Delhi. He also served as an international civil servant with
    the Commonwealth Secretariat in London.
    He was India’s High Commissioner to Mozambique and the Kingdom of Swaziland
    from January 2014 to early 2016, and Ambassador to Israel from March 2016 to
    September 2019.
    Ambassador Kapoor has an MBA from the Indian Institute of Management in
    Ahmedabad, India and a Masters in International Political Economy from the
    London School of Economics.`,
    ],
  },
  {
    name: "Her Excellency Marcy Grossman",
    title: "Canadian Ambassador to the UAE",
    company: "",
    image: "marcy.jpg",
    text: [],
  },
  {
    name: "Her Excellency Ambassador Houda Nonoo",
    title: "Ambassador at Ministry of Foreign Affairs",
    company: "Kingdom of Bahrain",
    image: "nonoo.png",
    text: [
      `H.E. Ambassador Houda Nonoo currently serves as an Ambassador at the Ministry of Foreign Affairs (MoFA). She served as Ambassador Extraordinary and Plenipotentiary of the Kingdom of Bahrain to Washington DC and non-resident Ambassador to Argentina, Brazil, Canada and Mexico. Prior to joining MoFA, she was appointed by His Majesty King Hamad Bin Salman Al Khalifa to the Shura Council, where she served on the committee for Finance and Economic Affairs between 2006 and 2008. 
    Ambassador Nonoo is the first – and to this day, the only – Jewish ambassador to be appointed from an Arab country.
    `,
    ],
  },
  {
    name: "His Excellency Fahad Al Gergawi",
    title: "CEO",
    company: "Dubai FDI",
    image: "fahad.jpg",
    text: [
      `His Excellency Fahad Al Gergawi is the Chief Executive Officer of
    the Dubai Investment Development Agency (Dubai FDI), an
    agency of Dubai Economy – Government of Dubai. In his Current
    role he leads a team that develops, promotes and supports the
    foreign direct investment (FDI) environment and projects in
    Dubai.
    
    H.E. is the President of the World Association of Investment Promotion Agencies (WAIPA),
    leading the global FDI body from 2019 to 2021. He also serves as the Secretary General of
    Hamdan Centre for the Future of Investment (HCFI), where he leads a global program to
    promote "Impact FDI" as a key to achieve Sustainable Development Goals (SDGs). Al Gergawi is
    a member of Dubai Economy’s Executive Committee; chairs the Dubai Investment Week
    organizing committee and sits on the advisory board of MODUL University in Dubai.
    
    Al Gergawi holds a Bachelor degree from the United Arab Emirates University, and is a graduate
    of the elite Mohammed Bin Rashid Al Maktoum inaugural program for Young Business Leaders.`,
    ],
  },
  {
    name: "Dr. Marwan Al Mulla",
    title: "CEO",
    company: "Dubai Health Authority",
    image: "marwan.jpg",
    text: [
      `As CEO of Health Regulation Sector, Dubai Health Authority (DHA), Dr. Marwan Al Mulla is
    responsible for the establishment of regulatory standards, policies and legislations for the
    licensure of healthcare facilities and healthcare professionals; and supervision and inspection of
    healthcare professionals, facilities and health tourism services in Dubai. In addition, he also
    focuses on establishing necessary foundations and standards for the governance and
    management of health informatics.
    
    Dr. Marwan has more than 15 years of experience within the industry and has held a number of
    key positions in the healthcare sector with the Government of UAE. He currently serves as the
    CEO of Health Regulation Sector in Dubai Health Authority, a government organization
    overseeing the health system of Dubai. Prior to his new role as CEO, he was the Director of the
    Health Regulation Department and Head of Director General Office in DHA. Dr Al Mulla also
    served as Section Head for Health professional Licensing at Health Authority Abu Dhabi (HAAD).
    During his tenure at HAAD, he successfully developed the new Unified PQR (Unified Professional
    Qualifications Requirement) system and introduced the modified licensing model to enhance
    service levels within a very short span of time.
    
    Deeply committed towards improving the delivery of healthcare in the Emirates, Dr. Marwan has
    been highly instrumental in the development of the online Licensing System “Sheryan” the digital
    
    gateway for registering and licensing healthcare professionals and facilities in Dubai. The system
    effectively utilizes block chain technology in addition to Artificial Intelligence (AI) and Internet of
    Things (IoT). He led the launch of DHA’s ‘Doctor for Every Citizen’ initiative that provides free
    24/7 telehealth consultation to UAE citizens through the adoption of latest technologies. Under
    his leadership, the regulation sector had also introduced the new assessment model for
    professional licensing and launched the Primary Source Verification in cooperation with DHA’s
    strategic partners.
    
    Dr. Marwan Al Mulla earned his degree in Medicine and Surgery from The Royal College of
    Surgeons in Ireland in 2001. He followed his studies with MS in Health Management from the
    Royal College of Surgeons in Ireland (RCSI). He is a board member of ‘Emirates International
    Accreditation Centre’ and also board member of ‘National Institute for Health Specialties’.`,
    ],
  },
  {
    name: "Ramy Jallad",
    title: "Group CEO",
    company: "Ras Al Khaimah Economic Zone",
    image: "ramy.jpg",
    text: [
      `Ramy Jallad is the Chief Executive Officer of RAKEZ. His role is to develop and drive the
    strategic growth, creating a thriving commercial and industrial business hub that attracts and
    welcomes investors from all around the world. He serves as the leader and mentor to over 580
    employees in the economic zone, from different walks of life who are working in diverse fields
    of expertise. Mr Jallad has over 25 years of professional experience, where he has developed
    and led various commercial and industrial free zones, business parks and mixed-use real
    estate developments.`,
    ],
  },
  {
    name: "Sherif Beshara",
    title: "CEO",
    company: "Mohamed & Obaid Almulla Group of Companies",
    image: "sherif.jpg",
    text: [
      `A native of Egypt and holder of a master’s in international law, Sherif is a seasoned veteran in the legal industry whose career spans over a decade with tenures as a legal counsel, chairman, and, most recently, Group CEO of Mohd & Obeid Al Mulla Group of Companies. His experience covers a spectrum of fields, comprising financial, aviation, and corporate, with responsibilities that included advising CEOs and counselling on airline matters, major JV projects, and uninsured litigations.
<br/>
    Sherif, a member of Legal 500 Middle East 2016 – 2017, is instrumental in leading and guiding the vision of the group, developing and leading internal audit and corporate compliance programmes, and sparticipating in the formulation of general management policy as a member of the executive management team. As Group CEO, he now oversees the whole portfolio of Al Mulla Groups' companies.
    `,
    ],
  },
  {
    name: "Khalid Anib",
    title: "CEO",
    company: "Abu Dhabi National Hotels PJSC",
    image: "anib.jpg",
    text: [
      `With more than 25 years in the hospitality industry, Khalid had the opportunity to manage various
    hotels under well-reputed international hotel chains in different countries.
    Khalid has had exposure to different business models, resorts, city hotels and mix used development.
    Before joining Abu Dhabi National Hotels, Khalid spent 5 years in the Kingdom of Saudi Arabia. He was
    the Managing Director of Al Hokair Group, where he played a key role in the group’s successful IPO.
    Prior to that, Khalid worked as General Manager for Accor & Banyan Tree.
    Khalid a 55 yr old Finnish citizen of Moroccan origin, holds a Master’s in Business Administration from
    the Strathclyde University, United Kingdom.`,
    ],
  },
  // {
  //   name: "Yagub Yousef Alserkal",
  //   title: "Founder and Chief Executive Officer",
  //   company: "YAS Investments",
  //   image: "speaker.png",
  //   text: [],
  // },
  {
    name: "Sheikha AlNuaimi",
    title: "Commercial Operations Executive Director",
    company: "Ajman Free Zone",
    image: "AlNuaimi.jpg",
    text: [],
  },
  {
    name: "His Excellency Dr. Raphael Nagel",
    title: " Investor, Lobbyist",
    company: "Philanthropist",
    image: "nagel.png",
    text: [
      `A man of his time, Raphael Nagel is a renowned investor, a lobbyist and a
    philanthropist. In 2020, he was awarded by the Indian financial magazine Business
    APAC as one of the leading Investment Gurus of the said year. Besides, he was also
    awarded a prize by the Business Worldwide Magazine (BWM) CEO Awards as the
    UAE’s Financial Services CEO of the current year.`,
      `
    His tireless entrepreneurial spirit has led him to chair the Board of The Abrahamic
    Business Circle, an innovative global interfaith business dialogue forum based in the
    United Arab of Emirates.`,
      `
    Before devoting himself to business and entrepreneurship, Mr. Nagel’s academic
    background in Economics and Law has been forged in university centers in
    Germany, the United Kingdom and Spain.`,
      `
    Amongst other relevant activities, he is an active member of the Scientific Committee
    of the Chair in Security at the Andorra’s Universitat Europea. Additionally, he is the
    co-founder of a Spanish investment bank specialized on cross-border transactions
    and debt restructuring. In 2016, he went above and beyond the call of duty to help
    others and he founded a Charity Trust in order to combat the social exclusion
    caused by over-indebtedness and give support to basic overall financial education
    and awareness.`,
      `
    Amongst his many occupations, he found the time to author and edit several books
    on highly relevant issues such as Economics and Geopolitics.`,
      `
    Over the years, Mr. Nagel has been an energetic member of various important
    working groups as well as Senior Advisor to major companies, both within and
    beyond Europe. Highly reputed as an Independent Consultant on Economic Policy,
    throughout his long career he has advised a number of public bodies and
    Governments all over the world always aiming towards global excellence.`,
    ],
  },

  // {
  //   name: "Bassel Kassem",
  //   title: "Regional Manager South Gulf",
  //   company: "Nutanix",
  //   image: "speaker.png",
  //   text: [],
  // },
  {
    name: "Felix Moral",
    title: "CEO",
    company: "Rise International (a National Holding Company)",
    image: "felix.jpg",
    text: [
      `Felix Moral joined Rise in 2020 as the Chief Executive Officer. He is part of
    the team of National Holding since 2016 and, before to join Rise, he has
    been successfully leading Exeed Industries as the Chief Executive Officer.
    He is an European Industrial Engineer and has a PDG in Business
    Administration from the IESE Business School (University of Navarra,
    Spain).
    <br/>
    Prior to joining the National Holding team, Mr. Felix was the General
    Manager of PSS LLC, Mazrui Group and Director of the Board for
    Overseas AST LLC in Dubai. During his three-year tenure, Felix
    implemented a completely new business model and strategy, integrated
    and implemented the Mazrui Group global strategy and facilitated
    international partnership agreements for developing new markets.
    <br/>
    Before the UAE, Felix spent the majority of his career in Spain and Belgium
    as General Manager of De Neef Spain, Europe VP for De Neef Group
    (Belgium) and CEO of Katorce Group in Barcelona, where he established
    strategic alliances in Europe, whilst initiating and completing multiple
    acquisitions that resulted in procurement of new technologies and markets.
    <br/>
    Felix is a passionate leader with a long track record of successful
    management and the ability to develop the vision of any company he is
    managing.`,
    ],
  },
  // {
  //   name: "Faizal E. Kottikollon",
  //   title: "Founder & Chairman ",
  //   company: "KEF Holdings | Meitra Hospital | Faizal & Shabana Foundation",
  //   image: "faizal.jpg",
  //   text: [
  //     `A visionary industrialist and devoted philanthropist – Faizal E. Kottikollon is the Founder
  //   and Chairman of KEF Holdings: an industry-agnostic, privately-owned family company,
  //   steeped in more than 25 years of innovation and shaped by a mission to be different
  //   and make a difference. He is also the Chairman of the Board at Meitra Hospital in
  //   Calicut, Kerala, India, and Co-Founder/Vice Chairperson of The Faizal & Shabana
  //   Foundation, which is committed to initiating positive change and creating lasting impact
  //   by continuously striving to be catalysts for the creation of an equitable, happier and
  //   better tomorrow.
  //   Born to a family of industrialists in Mahé, India, Faizal exhibited entrepreneurial flair and
  //   an immense work ethic from a very early age. After earning his bachelor’s degree in
  //   Civil Engineering from Manipal Institute of Technology, he enrolled at T.A. Pai
  //   Management Institute and graduated with his MBA in Business Administration,
  //   Management & Operations. He later obtained a master’s degree in Industrial
  //   Engineering from Bradley University in the USA.
  //   Faizal took the first big step in his entrepreneurial journey in 1995 when he set up his
  //   first company, Al Ahmadi General Trading, a scrap metal trading business in Ajman,
  //   UAE. From there, he saw immense opportunities for growth. Eager to capitalise on his
  //   early success, in 1997, he opened Emirates Techno Casting (ETC), a world-class
  //   foundry and campus in Sharjah, UAE.
  //   From day one, ETC was built to be different. It was the first foundry in the UAE to
  //   implement manufacturing integration and was one of only two foundries in the world to
  //   include a Vacuum Oxygen Argon Degassing Furnace. By 2007, the fully integrated
  //   facility was rated amongst the top three technologically advanced foundries in the world.
  //   In 2012, Faizal successfully sold the flagship venture for US $400 million to TYCO
  //   International.
  //   During his time as Chairman of ETC, Faizal and his wife, Shabana, were proactive
  //   exponents of positive industry change. KEF Holdings invested US $5 million into a
  //   state-of-the-art community centre on the ETC campus, to cater to all employees’
  //   physical, mental and wellness needs, while providing opportunities for personal and
  //   educational development.
  //   Following ETC’s acquisition by TYCO, Randall Hogan, Chairman & CEO of Pentair,
  //   renamed the community centre, ‘The Shabana & Faizal Community Centre’ – a fitting
  //   tribute to Faizal and Shabana’s unwavering determination to make a difference.`,
  //   ],
  // },
  // {
  //   name: "Abdullah Saeed Al Naboodah",
  //   title: "Chairman",
  //   company: "Al Naboodah Investments Company",
  //   image: "speaker.png",
  //   text: [],
  // },
  // {
  //   name: "Alan Smith",
  //   title: "Chief Executive Officer",
  //   company: "Agthia Group PJSC",
  //   image: "alan.png",
  //   text: [
  //     `Alan Smith joined Agthia Group PJSC in 2020 and is currently the Chief Executive
  //   Officer of the Group, responsible for the company’s growth and development.

  //   Prior to his appointment as CEO, Alan helmed various roles at Mondelez
  //   International; the most recent one being Managing Director – Middle East &
  //   Pakistan

  //   Alan brings over 20 years of encompassing FMCG experience with a focus on
  //   opening new markets and transforming underperforming operations. He also has
  //   strong cross functional experience having led strategic initiatives, business
  //   turnarounds, manufacturing build outs and led multi-country operations as a
  //   business leader. He holds an Executive Masters in Marketing from INSEAD and a
  //   Bachelor of Engineering (Mechanical) from Manchester University.`,
  //   ],
  // },

  // {
  //   name: "Adel Ahmad Al Redha",
  //   title: "Chief Operating Officer",
  //   company: "Emirates Airline",
  //   image: "adel.jpg",
  //   text: [
  //     `Since joining Emirates in 1985, Mr Al Redha has been a pivotal player within the operations team in various capacities. He has progressed his career and widened responsibilities with the growth of the company. His experience over the years has covered all aspects of operations and logistics which includes engineering, flight operations, service delivery, airport services, network operations and aircraft procurement.
  // <br/>
  //     Mr Al Redha was appointed to his current role in 2013. In his various roles with the company, he was instrumental in introducing ab-initio cadet programmes, engineering apprenticeships, and air transport management for UAE nationals pursuing a career in aviation. He also played a vital role in expanding the engineering and maintenance department including the state-of-the-art engine maintenance shop and the establishment of the Emirates Flight Training Academy.
  //     <br/>
  //     He is a key member in the definition of aircraft specifications, and in scoping the Emirates product in relation to cabin interiors and inflight entertainment. In addition, he has been instrumental in transforming into the digital process across all aspects of operations, most recently the introduction of paperless cockpit and use of biometric machines and automated passenger movement within the airport.
  //     <br/>
  //     A Fellow of the Royal Aeronautical Society, Mr Al Redha holds a Bachelor of Science in Engineering Technology/Aircraft Maintenance from the University of Northrop, USA, and a Master’s degree in Air Transport from Cranfield University, UK.
  //     `,
  //   ],
  // },
  {
    name: "Avishesha Bhojani",
    title: "CEO",
    company: "BPG Group",
    image: "avishesha.jpg",
    text: [
      `Avi's 35+ year career spans Marketing Services, Media, Education, Private Equity, Real Estate Direct Investments and Government sectors. At the helm of the BPG Group (www.bpggroup.com) since 1991, Avi consolidates the Group's interests across advertising, public relations, design, activation, media asset management and digital verticals, in the Middle East and North Africa region.`,
      `
      Avi has also assisted in the conceptualization and execution of a number of strategic Dubai initiatives (Dubai Quality Award, Dubai Shopping Festival etc.) and has served the Government of Dubai while assisting with the development of Knowledge Economy hubs in Dubai (Internet, Media and Ideas).`,
      `
      An Alumnus of the National Institute of Design, Ahmedabad and The Indian Institute of Management, Bangalore, Avi is also the Managing Director of Innoventures Education (www.innoventureseducation.com) the operators of Dubai International Academy and various Raffles Schools & nurseries etc.; General Partner of Montana Capital Ltd, the holding company of  Ramusake and Copper Dog in Dubai , AMEA Ventures Ltd, Partner Director of HTMi Switzerland Dubai LLC,  and Chairman of International Management Ventures FZ LLC. He is also on the Board of Governors of The International Institute of Tolerance by HH Sh Mohammed Bin Rashid Al Maktoum, the VP/ PM of UAE and Ruler of Dubai. `,
      `
      Avi is widely recognized as one of the most influential expats in the GCC, and has been honoured by                 H. H. Sheikh Mohammed Bin Rashid Al Maktoum for his contribution to the evolution of Dubai Shopping Festival. Avi has also been recognized by leading media such as Forbes Middle East and Arabian Business, both as an Entrepreneur of the Year as well as a Chief Executive of a Media company.
      `,
    ],
  },
  {
    name: "Dr. Tatiana A. Zhelinova MD, PHD",
    title: "Managing Director Med Curator UAE",
    company: "Medical director Health Plus IMA",
    image: "tatiana.jpg",
    text: [],
  },
  {
    name: "Dr. Ali al-Nuami",
    title:
      "Member of the UAE Federal National Council for the Emirate of abu Dhabi and Chairman of the Defense Affairs, Interior & Foreign Affairs Committee",
    company: "",
    image: "alinuami.jpg",
    text: [],
  },
  {
    name: "Les Male",
    title: "Chief Executive Officer",
    company: "Dubai Gold & Commodities Exchange (DGCX)",
    image: "les.jpg",
    text: [
      `Les Male is the Chief Executive Officer of the Dubai Gold & Commodities Exchange (DGCX), the largest
    and most diversified derivatives Exchange in the Middle East. Les has nearly 30 years experience in the
    Global Commodities industry with particular expertise across Exchanges, risk management and trading
    systems. As an extensively networked business leader with a consistent track record of driving new
    business, he builds outstanding customer relationships, whilst protecting and growing shareholder
    return.
    Les joined DGCX from NASDAQ where he was responsible for client facing activities on the Global
    Commodities product suite across UK based Prop shops, brokers, market makers, trading companies and
    Clearing Merchants (GCM’s) trading crude oil, fuel oil, gas and power derivatives and OTC swap
    contracts.
    A double Engineering graduate from the University of Bath, he joined British Gas as a pipeline engineer
    before managing strategic projects for the Network Code, culminating in leading the development of
    Europe’s first gas spot market (the OCM). He later became Commercial Director at EnMO and then
    continued in that role for APX-Endex, the Anglo-Dutch European gas and power trading Exchange. In
    2010 he became the European Managing Director for the Green Exchange, a US backed venture for
    global carbon trading. After acquisition by the CME Group, his role took on the European gas and Global
    LNG strategy for NYMEX, prior to joining NASDAQ in 2014.`,
    ],
  },
  {
    name: "Mohammed Zainabi",
    title: "Editor-in-chief",
    company:
      "L'OBSERVATEUR DU MAROC AND DIGITAL MEDIAS OF GLOBAL MEDIA HOLDING",
    image: "mohammed.jpg",
    text: [],
  },

  // {
  //   name: "Abdou Souleye Diop",
  //   title:
  //     "President of the African commission of the The General Confederation of Moroccan Enterprises (CGEM)",
  //   company: "",
  //   image: "Abdou.jpg",
  //   text: [],
  // },
  {
    name: "Mounssif Adarkaoui ",
    title: "Director",
    company:
      "Financial studies and forecasting in the Ministry of Economy and Finance",
    image: "Mounssif.jpg",
    text: [
      `Mr. Mounssif ADERKAOUI is currently the head of the Department of Studies and Financial Forecasting at the Ministry of Economy, Finance and Administration Reform in Morocco. Furthermore, he has served in several high positions in the private and public sectors, including as Director of Strategy.
    His field of expertise covers notably issues related to the design and implementation of public policies and reforms in priority areas of state action.
    Mr. ADERKAOUI has a rich experience at international level, especially in the IT and consulting sectors in France.
    He is a member of several scientific committees of various Moroccan and foreign institutions with an economic mandate.
    
    In addition to his Diploma as Engineer from Ecole Polytechnique of Paris and from Ecole Telecom Paris Tech (France), Mr. Mounssif ADERKAOUI holds a Post Master’s from University of Bristol (UK).
    
     `,
    ],
  },
  {
    name: "Mehdi Tazi",
    title: "Vice President",
    company: "General Confederation of Moroccan Enterprises (CGEM)",
    image: "mehdi.jpg",
    text: [
      `Born in Casablanca, Morocco, Mr. Mehdi TAZI graduated from the engineering and
    management school Télécom Paris Sud in 1999 and received an MBA from the
    European Institute of Business Administration (INSEAD) in 2004.
    He began his professional career in KPMG Consulting France in 1999.
    In 2005, he joined Saham Group as Development Director.
    In 2008, he was appointed Managing Director of Isaaf Mondial Assistance, a
    subsidiary of the group, after serving as Deputy Managing Director for 14 months.
    In 2014, he became the CEO of Saham Assurance after spending 3 years as
    Managing Director.
    Between 2010 and 2017, he was a board member of several insurance companies
    of the Group : Saham Insurance Morocco, Niger, Rwanda, and Angola, as well as
    Taslif and CAT Morocco.
    He also held the position of CEO of GA Angola Seguros from 2013 to 2014.
    In 2017, Mr. TAZI acquired the firm Beassur and sold, two years later, a minority
    share to Marsh, the world leader in insurance brokerage and risk management.
    Mr. TAZI was distinguished by the Jeune Afrique Group, in 2013, among the 25
    young African leaders and, in 2020, among the top 50 disruptors.
    He was chosen to be amongst the Top 10 economic leaders of the continent by the
    prestigious Choiseul Institute for three consecutive years from 2014 to 2016.
    In terms of his civic engagement, Mehdi TAZI was Vice-President of the Moroccan
    Federation of Insurance and Reinsurance Companies (FMSAR) from 2010 to 2017.
    He also spent four years from 2010 to 2014 as the President of the Royal Moroccan
    Federation of Sports for All (FRMSPT).
    Within CGEM, he served as Vice-President in charge of the New Growth Drivers
    before being elected by his peers on the 22 nd of January 2020, as General Vice-
    President of the Confederation.`,
    ],
  },

  {
    name: "Ronald S. Lauder",
    title: "",
    company:
      "President, World Jewish Congress & Chairman Emeritus of Estée Lauder Companies",
    image: "lauder_1.jpg",
    text: [],
  },
  {
    name: "Paul Packer",
    title: "Chairman of the US Commission for the Preservation",
    company: "America’s Heritage Abroad",
    image: "paul.jpg",
    text: [],
  },
  // {
  //   name: "Sean Murphy",
  //   title: "",
  //   company: "US Ambassador the UAE",
  //   image: "speaker.png",
  //   text: [],
  // },
  {
    name: "Eitan Na’eh",
    title: "Israeli Ambassador",
    company: "United Arab Emirates",
    image: "eitan.jpg",
    text: [],
  },
  {
    name: "Zohar Elhanani",
    title: "CEO",
    company: "Zebra Medical Vision",
    image: "zohar.jpg",
    text: [
      `Zohar brings over 25 years of experience in a variety of leadership roles across startup and growth businesses, industries and geographies. He returned to Israel after spending 16 years in the US, most recently as CEO of MutualArt, a leading provider of art market data, analysis and advanced decision support tools. Zohar had previously spent over a decade as President and CEO of Legacy Benefits, LLC, a specialty finance firm focused on longevity-linked markets for life insurance, where he was hired to lead the transformation of a privately held business into a growth oriented enterprise, driving its expansion and ultimate sale.  Prior to joining Legacy Benefits, Zohar served as Executive VP of Sales & Operations for RealTimeImage, Inc., a healthcare IT company offering digital image distribution and archival solutions in areas of radiology, cardiology and orthopedics. The company was acquired by IDX Systems Corporation, and subsequently by GE Healthcare, where Zohar served as Managing Director of the acquired business. Earlier in his career, Zohar held various positions involving corporate and business development as well as investments in the technology field as part of a venture capital fund. Zohar holds an MBA from Solvay Business School in Brussels, Belgium from which he graduated summa cum laude, as well as dual degrees in Law and Economics from Tel Aviv University in Israel.`,
    ],
  },

  {
    name: "Eli Cohen",
    title: "CEO ",
    company: " Mekorot National Water Company",
    image: "eli.jpg",
    text: [],
  },
  {
    name: "Lee Ziv",
    title: "VP Sales & Marketing",
    company: "Port Tel Aviv Residence",
    image: "lee.jpg",
    text: [
      `Lee specializes in global luxury properties, where she has successfully marketed and sold exclusive
    apartments and houses valued at over half a billion dollars.<br/>
    Lee has an extensive background in managing clients and projects.<br/>
    <br/>
    She started her career as an account manager in a leading ad agency, where she was responsible for top clients including a leading Israeli bank.
    <br/>
    Leveraging her strong creativity and interpersonal skills, Lee joined Israel's leading television channel 2
    where she was in charge of marketing and selling airtime to premium clients.<br/>
    Lee is also among a founding crew member of Israel's premier music channel, Music 24.<br/>
    In 2011, Lee joined as VP Marketing & Sales of Berggruen residential which built
    "Meier on Rothschild" tower in Tel Aviv by renowned architect Richard Meier. it was an unbelievable
    success with a profit of over NIS 500 million and 141 apartments sold in just a single tower.`,
    ],
  },
  {
    name: "Ilanit Melchior",
    title: "Director of Tourism",
    company: "Jerusalem Development Authority",
    image: "ilanit.jpg",
    text: [],
  },

  {
    name: "Haim Bibas",
    title: "",
    company:
      "Chairman of the Federation of Local Authorities & Mayor of Modi'in Maccabim Reut",
    image: "haim.jpg",
    text: [],
  },
  {
    name: "Massimo Falcioni",
    title: "CEO",
    company: "Etihad Credit Insurance",
    image: "massimo.jpg",
    text: [],
  },

  {
    name: "Prof. Rafael Bayer",
    title: "Co-Founder and Managing General Partner ",
    company: "ALIVE HealthTech Fund",
    image: "rafael.jpg",
    text: [
      `Prof. Rafael Beyar is Co-Founder and Managing General Partner of the ALIVE HealthTech Fund.
    Prof. Beyar was the former Director & CEO of Rambam Health Care Campus, one of Israel’s
    largest hospitals. He is a practicing interventional cardiologist, researcher, innovator, and
    entrepreneur, co-author of more than 300 publications and 15 books, and the recipient of
    numerous awards.
    Prof. Beyar led Rambam Hospital through an unprecedented development combining
    education, research, and innovation. He established the tech-transfer company, Rambam
    MedTech and spearheaded the hospital's role for establishment of the MindUp Incubator
    focused on Digital Health.
    Prof. Beyar co-founded InStent (1989) sold to Medtronic (1996) and founded NaviCath (Later
    Corindus) (2002) who developed the world’s first robotic coronary intervention, sold to
    Siemens Healthineers (2019). He also founded the world’s leading conference, the Innovation in
    Cardiovascular Interventions Meeting (ICI) in 1996.`,
    ],
  },

  {
    name: "  Itzik Ozer",
    title: "Head of Business Development",
    company: "Jerusalem Development Authority",
    image: "itzik.jpg",
    text: [],
  },
  {
    name: "Tomer Shani",
    title: "Co-Founder & CTO",
    company: "Noveto",
    image: "Tomer.jpg",
    text: [],
  },
  {
    name: "Moti Shiri",
    title: "VP Engineering & Technology",
    company: "Mekorot National Water Company",
    image: "Moti.jpg",
    text: [],
  },
  {
    name: "Vera Futorjanski",
    title: "CEO & Founder",
    company: "Veritas Ventures",
    image: "vera.jpg",
    text: [],
  },
  {
    name: "Natalie Milstein",
    title: " Cross-border Business Specialist & VC Investment Analyst",
    company: "",
    image: "natali.jpg",
    text: [],
  },

  {
    name: "Noam Solomon",
    title: "CEO & Co-Founder",
    company: "Immunai",
    image: "Noam.jpg",
    text: [],
  },
  {
    name: "Daniel Seal",
    title: "CEO & Founder",
    company: "Unbound",
    image: "daniel.jpg",
    text: [
      `Daniel is a serial entrepreneur & angel investor. He is the Founder & Managing Partner of DeltaWorks,
    a new breed of venture building focusing on emerging markets. He is a thought leader and frequent
    keynote speaker on all aspects of startup ecosystem creation and governments role in innovation. In
    2018, he sold a controlling stake in Unbound to SingEx (a wholly owned subsidiary of Temasek
    Holdings, Singapore). He was previously was one of the youngest elected officials to the London
    Borough of Barnet representing Garden Suburb Ward. Daniel has a BSc (Hons.) in Biochemistry and an
    MSc Business Management from the University of London.`,
    ],
  },

  {
    name: "Steve  O'Hana",
    title: "President",
    company: "Morocco-Israel Business Council",
    image: "steve.jpg",
    text: [
      `Steve O'HANA is an alumnus of Paris Dauphine University and holds a master’s
    degree in Finance from the American University of Washington DC.
    O’HANA first joined the sugar trade department of ED&F Man in London from 1980
    to 1981.
    He then started his career as an Assistant General Manager of the CMCP Group, a
    leading supplier of corrugated boxes in Morocco and was appointed CEO of the group
    in 1990, managing a team of 2,500 people and achieving a turnover of 120 million
    dollars.
    Between 1990 and 2000, Steve O'HANA was also the CEO of the trading company,
    Comptoir National Marocain.
    Today, Steve O'HANA runs several companies : Société Chérifienne de Remorquage
    et d'Assistance (SCRA) (since 2004), the financial holding MAGEF (since 2005), the
    real estate holding COGEFIN (since 2007), JL Tug and FEDALA Tug.
    Since march 2021, he is the President of the Morocco-Israel Business Council of the
    General Confederation of Moroccan Companies (CGEM).`,
    ],
  },

  {
    name: "Yaky Yanay",
    title: "CEO & President",
    company: "Pluristem",
    image: "Yaky.jpg",
    text: [],
  },
  {
    name: "Gil Shwed",
    title: "CEO",
    company: "Check Point Software Technologies Ltd",
    image: "gil.jpg",
    text: [],
  },
  {
    name: "Jonathan  William  Medved",
    title: "Founder and CEO",
    company: "OurCrowd",
    image: "Jonathan.jpg",
    text: [
      `Jonathan Medved is a serial entrepreneur and according to the Washington
    Post (2007) “one of Israel’s leading high-tech venture capitalists”. In the
    2008 NY Times Supplement “Israel at 60” Medved was named one of the
    top 10 most influential Americans who have impacted Israel.
    Medved is the founder and CEO of OurCrowd, the leading global equity
    crowdfunding platform for accredited investors and angels. OurCrowd has
    $1.8B in commitments and has made investments in more than 255
    companies, in 25 funds and has seen 46 exits since its launch in February
    2013. OurCrowd exits include: Jump Bikes sold to Uber, Briefcam sold to
    Canon, Argus sold to Continental, Crosswise sold to Oracle, and Replay sold
    to Intel. Bloomberg Business week said in the May 7, 2015 edition that
    “OurCrowd is hands down the most successful equity-crowdfunding
    platform in the world right now.” TheStreet.com described OurCrowd as
    “Crowdfunding for Real Investors.”
    
    Prior to OurCrowd, Medved was the co-founder and CEO of Vringo
    (Nasdaq:VRNG) and the co-founder and General Partner of Israel Seed
    Partners, with $262M under management.`,
    ],
  },
  {
    name: " Ruth Wasserman Lande",
    title:
      "Former MK, Former Senior Advisor to President Shimon Peres, CEO and Founder",
    company: "Ruth Global Innovative Advisory",
    image: "Ruth.jpg",
    text: [
      `Ruth was born in Israel and raised in Cape Town, South Africa.
    After arriving in Israel, she graduated, cum laude, in International Relations and Communications
    from Bar Ilan University. She completed, Magna cum Laude, her Master of Arts in International
    Relations at the Hebrew University in Jerusalem. In 2006, she was awarded a Wexner Fellowship
    to study for a Masters’ Degree in Public Administration at Harvard University, from where she
    graduated, cum laude in June 2007
    Ruth served for three years as an analyst in Israeli Defense Force intelligence, rising to the rank of
    Captain. Ruth continues to do reserve duty as an analyst in the IDF.
    Professionally, Ruth served as an Israeli diplomat for 13 years in the Ministry of Foreign Affairs,
    and was appointed to serve for three years, as the political and economic advisor in the Israeli
    embassy in Cairo, completing her service there as the de facto Deputy Chief of Mission. After
    graduation from Harvard, Ruth was appointed as Foreign Affairs and World Jewish Affairs’
    advisor to former President of Israel, Mr. Shimon Peres.
    She served for almost a decade as the Deputy Director General of the Israeli Federation of Local
    Governments, working with all Israeli Mayors, both Jewish and Arab, throughout the country.
    Ms Wasserman Lande worked, on a voluntary basis, on the promotion and development of one of
    Israel’s poorest, mixed Jewish-Arab cities in Israel, Lod, where she also lived for a decade with
    her husband and three children. She has also founded (2018) and continues to chair the
    "Hagshama" initiative, a non-for-profit and non-political NGO, for creating real impact on Israeli
    society, via a country-wide initiative to transform one of the Bedouin villages in the North of the
    country into a model for emulation. That is, in coordination with its elected leadership, government
    ministries, philanthropists, businesspeople and the local community.
    In July 2019, Ruth established her own strategic local and global consultancy which assists a wide
    variety of bodies and/or individuals -both Israeli and foreign- in identifying their needs, creating
    policy, establishing objectives and accompanying their accomplishment and fulfillment.
    
    She is also a columnist in one of Israel’s leading dailies, in Hebrew, “Maariv”, and in the English-
    language “Jerusalem Post”. Ruth also often commentates on Regional and Arab-Israeli Affairs in
    
    local and international television channels.
    Ruth has recently entered the Israeli Knesset as an MK, representing the Blue and White party.
    She speaks Hebrew, English, Russian and Arabic.`,
    ],
  },

  {
    name: "Morris Kahn",
    title: "Entrepreneur/ Philanthropist, Founder",
    company: "AMDOCS /SpaceIL",
    image: "morris.jpg",
    text: [
      `Founder of Amdocs, one of Israel’s largest, and most established high-tech companies in the
    world.`,
      `Foresight and business acumen were key to achieving his first large-scale success in the
    business world with the establishment of Israel’s Golden (Yellow) Pages, Morris also
    founded the first World underwater observatory in Eilat – a revolutionary concept that his
    company Coral World International also ‘exported’ to other countries. Morris co-founded the
    Aurum Group, and proceeded to establish companies in diverse fields, including, among
    others, Golden Lines and Golden Channels, and was instrumental in the entry of global
    corporations AT&T and AIG into the Israeli market.`,
      `Yellow Pages developed an advanced software system was the springboard for the birth of
    AMDOCS which today provides services around the entire world. Morris understood the
    importance of databases for business and economic development, and transformed Amdocs
    into a global leader in its field. The technological developments, high standards and
    corporate culture promoted by Amdocs laid the groundwork for the high-tech industry in
    Israel, also known as the Start-Up Nation.`,
      `Striving to make the world a better place for present and future generations, His business
    success has made it possible for Morris to dedicate most of his time and fortune to his true
    passion: philanthropy. Here, too, he has broken barrier`,
      `His philanthropic work is diverse and original.`,
      `LEAD - leadership development program providing tools for our next generation of leaders.
    Jinka eye camp –Ethiopia- Due too many reasons – such as certain cultural traditions, poor
    nutrition, the lack of proper sanitation, and the absence of any ophthalmological services –
    many of the region's inhabitants suffer from eye problems, primarily cataracts and trachoma.
    The incidence of blindness due to cataracts in this region is among the highest in the world.
    In view of this situation, Morris chose to focus on Jinka for the eye surgery project with the
    clear goal of establishing an independent ophthalmology unit but most important – providing
    people with the ability to see.
    The Therapeutic Riding Center in Tel Mond; Jordan River Village, for children with terminal
    diseases.;`,
      `Save a Child's Heart, an NPO for life-saving cardiac surgery for children in developing
    countries, which has provided surgery for 4,000 children and trained local teams in a variety
    of countries - a project that has shown the beautiful face of Israel to the world;
    Morris has shown a strong interest in medical research for the development of cures for
    serious diseases. This passion has led to numerous partnerships,
    He supports, among others, cutting-edge cancer stem cell research, including the
    establishment of a laboratory for fertility preservation among cancer patients in Tel
    
    Hashomer.; a bio-medical research laboratory focusing on genetics and genetic mapping;
    the establishment of Israel’s national Institute for Biotechnology, and the establishment of a
    Center for Systems Biology of the Human Cell at the world-renowned Weizmann
    Institute.The most recent collaboration was establishing the Morris Kahn & Maccabi health
    data institute to enhance human health by providing technological platform that will support
    medical breakthroughs in the fields of predictive analysis and precision medicine and
    significantly increase the amount of research being performed at any given moment.`,
      `Morris Kahn, met SpaceIL’s founders shortly after they established the non-profit in 2011. In
    their daring self-declared mission to land the first Israeli spacecraft on the moon, he
    recognized his own adventurous spirit, and donated the first $100,000 to help them pursue
    their dream. Morris identified with the desire of Yariv Bash, Kfir Damari and Yonatan
    Winetraub to accomplish an extraordinary feat, which would advance the Israeli civilian
    space industry and put Israel on the map as the fourth country to land on the moon – an
    achievement previously accomplished only by three superpowers. But even more
    importantly, he recognized the importance of the broader mission – to inspire Israel’s young
    generation to seek a future in science, technology, engineering and mathematics; to lay
    down the infrastructure that would secure Israel’s future as the startup nation for generations
    to come.`,
      `Since his initial meeting with SpaceIL’s three founders, Morris has been the non-profit’s
    strongest, most dedicated and committed supporter and backer. He has not only generously
    donated $40 million, but importantly, has been closely involved with the project’s
    development every step of the way. As SpaceIL’s President , he has been instrumental in
    forming collaborations with Israel Aerospace Industries (IAI) and the Israeli Space Agency,
    has recruited a highly qualified management team, and has enlisted the support of leading
    members of the scientific community, the academia and fellow philanthropists. He has been
    with the team through thick and thin, as they achieved one milestone after the other, helping
    them overcome obstacles that seemed insurmountable at times, and celebrating victories
    with them. As the driving force behind SpaceIL, his role has been invaluable; without him,
    SpaceIL would not be where it is today – with its spacecraft Beresheet on its way to a
    successful landing on the moon.`,

      `Today, at the age of 89, with his grandchildren and great grandchildren by his side, Morris
    continues his far-reaching philanthropic activity modestly, and with the same sparkle in his
    eye as the young Zionist entrepreneur. His exemplary leadership has inspired generations of
    entrepreneurs and young dreamers. His desire to do good for humanity and to ensure the
    prosperity of the world continue to be his guiding principles.`,
    ],
  },
  {
    name: "Fleur Hassan Nahoum",
    title: "Deputy Mayor",
    company: "Jerusalem",
    image: "fleur.jpg",
    text: [],
  },
  // {
  //   name: "Moshe Lion",
  //   title: "Mayor",
  //   company: "",
  //   image: "moshe.jpg",
  //   text: [],
  // },
  {
    name: "Mark Gazit",
    title: "CEO",
    company: "ThetaRay",
    image: "gazit.jpg",
    text: [],
  },
  {
    name: "Sachhyam Regmi",
    title: "Founding Partner",
    company: "YAS Investments and Spartech Ventures",
    image: "sacchyam.jpg",
    text: [
      `Sachhyam is an investment professional with more than 11 years of private equity and
    venture capital experience working in New York, London and Dubai executing, managing and
    leading financial transactions totaling to USD 4.5bn`,
    ],
  },
  // {
  //   name: "Avi Cohen-Scali",
  //   title: "Director-General",
  //   company: "Ministry for Jerusalem and Heritage",
  //   image: "scali.png",
  //   text: [],
  // },
  {
    name: "Regev Gur",
    title: "Founder & CEO",
    company: "Narrative Group ",
    image: "regev.jpg",
    text: [],
  },
  {
    name: "Bruce Gurfein",
    title: "CEO",
    company: "Connect",
    image: "gurfien.jpg",
    text: [],
  },
  {
    name: "Ehud Olmert",
    title: "Former Prime Minister",
    company: "Israel",
    image: "ehud.jpg",
    text: [
      `Ehud Olmert served as Prime Minister from 2006 to 2009. He was first elected to the 8 th
    Knesset in 1973 at the age of 28 and was reelected eight times. In 1993, Olmert was elected
    as Mayor of Jerusalem and`,
      `
    in 2003, he returned to national politics holding many senior ministerial positions. In the
    March 2006 elections, Olmert led the Kadima Party to victory and was elected as the 12th
    Prime Minister of the State of Israel.`,
    ],
  },

  {
    name: "Yaakov Katz",
    title: "Editor In Chief",
    company: "The Jerusalem Post",
    image: "yaakov.jpg",
    text: [],
  },

  // {
  //   name: "Vinay Kamat",
  //   title: "Editor In Chief",
  //   company: "Khaleej Times",
  //   image: "speaker.png",
  //   text: [],
  // },
  // {
  //   name: "Abdul Karim Hanif",
  //   title: "Multimedia Journalist",
  //   company: "Khaleej Times",
  //   image: "abdul.jpg",
  //   text: [
  //     `Born in Saudi Arabia, brought up in Dubai and educated in the UK, Abdul Karim is a multicultural and a homegrown talent of the UAE, with over 5 years of experience in Broadcast Journalism.
  //  <br/>
  //  <br/>Currently, Abdul presents the morning breakfast talk-show 'Morning Majlis,' on Pulse 95 Radio and is also a multimedia journalist for UAE's national news outlet Khaleej Times.
  //  <br/>The international upbringing developed Abdul's ability to speak English, Urdu and Arabic.
  //  <br/>On the sidelines of his broadcast career, Abdul also works as an Emcee/Event Host and has hosted events ranging from product launches, forums, sports competitions, national day ceremonies for embassies, business conferences and internal corporate events.
  //     `,
  //   ],
  // },
  {
    name: "Michal Divon",
    title: "Senior Editor and Producer",
    company: "Khaleej Times",
    image: "michal.jpg",
    text: [
      `Michal Divon is an Israeli journalist and TV host working as a Senior Editor and Producer for Khaleej Times. Michal specializes in hosting live interviews, moderating panels, emceeing events, and producing video content across platforms. Michal has hosted high profile events such as the annual Israel Day Parade broadcast with Fox5 and Israeli Embassy Independence Day Celebration. Michal has interviewed key figures including UAE Ambassador to UN Lana Nusseibeh, Israeli Ambassador to US Ron Dermer, US Special Envoy Elan Carr, SpaceIL Morris Kahn and has written for Khaleej Times, Jpost, Israel Hayom, Times of Israel and The Forward. Michal holds a BA in Government, Diplomacy and Strategy from the Interdisciplinary Center in Herzliya.
      `,
    ],
  },

  {
    name: "Steve Linde",
    title: "Editor-in-chief",
    company: "The Jerusalem Report",
    image: "linde.jpg",
    text: [],
  },

  {
    name: "Ariella Steinreich",
    title: "Senior Vice President and Head of the Middle East Division",
    company: "Steinreich Communications",
    image: "ariella.jpg",
    text: [
      `Ariella Steinreich is a senior vice president at Steinreich Communications overseeing the firm’s global corporate / issues practice and its Middle Est division. She brings over a decade of experience at major public relations firms where she created and implemented global corporate communications programs for leading companies and organizations.  
    Her experience includes strategic media counseling, media training, communications strategy development, social media strategy, crisis communications/ issues management, financial communications, community relations and corporate social responsibility.
    Prior to joining the firm, she was a senior associate at Burson-Marsteller where she handled global media relations for several of the agency’s key clients. In 2014, she was named a finalist for PR Week’s Young Professional of the Year. 
    `,
    ],
  },
  {
    name: "Maayan Hoffman",
    title: "Head of Strategy & senior analyst",
    company: "The Jerusalem Post",
    image: "maayan.jpg",
    text: [],
  },
  {
    name: "Lahav Harkov",
    title: "Diplomatic Correspondent",
    company: "The Jerusalem Post",
    image: "lahav.jpg",
    text: [],
  },
  {
    name: "Anjana Sankar",
    title: " Assistant Editor",
    company: "Khaleej Times",
    image: "anjana.jpg",
    text: [
      `Anjana Sankar is a senior journalist who writes about global stories of conflict and migration. She has reported from the frontlines of the wars in Yemen and Syria and has covered the refugee crisis in Bangladesh, Iraq and Europe.  She has extensively written on the Abraham Accords and is the first UAE journalist to report on the pace deal from Israel.`,
    ],
  },

  // {
  //   name: "H.E. Ambassador Omar Saif Ghobash",
  //   title: "Assistant Minister for Cultural Affairs",
  //   company: "Ministry of Foreign Affairs and International Cooperation",
  //   image: "ghobash.jpg",
  //   text: [],
  // },

  // {
  //   name: "Sylvan Adams",
  //   title:
  //     "Businessman, philanthropist and activist, working to strengthen the State of Israel",
  //   company: "",
  //   image: "Sylvan.jpg",
  //   text: [],
  // },

  // {
  //   name: "Orit Farkash Hacohen",
  //   title: "Minister of Tourism",
  //   company: "Israel",
  //   image: "orit.jpg",
  //   text: [],
  // },

  // {
  //   name: "Eli Cohen",
  //   title: "Minister of Intelligence",
  //   company: "Israel",
  //   image: "elicohen.jpg",
  //   text: [],
  // },
  // {
  //   name: "Dr. Yuval Steinitz",
  //   title: "Minister of Energy",
  //   company: "Israel",
  //   image: "yuval.jpg",
  //   text: [],
  // },

  // {
  //   name: "Aryeh Lightstone",
  //   title: "",
  //   company: "U.S. Special Envoy for Economic Normalization",
  //   image: "lightstone.jpg",
  //   text: [],
  // },

  // {
  //   name: "Dr. Christophe Ramstein",
  //   title: "CEO and Member of the Board",
  //   company: "Noveto Systems Ltd.",
  //   image: "christophe.jpg",
  //   text: [
  //     `Christophe is passionate about user experience. He is a serial entrepreneur with significant experience in
  //   tech companies, specifically in licensing businesses, an area in which he led to significant deals and
  //   successful exits of three previous start-up companies. Prior to joining Noveto, Christophe was leading
  //   Myotest, a licensor of motion analysis algorithms and prior to that Global VP Engineering at Logitech
  //   where he was responsible for the launch of numerous products. Before assuming the role at Logitech,
  //   Christophe was the CTO at Immersion, a global licensor of haptic technology.`,
  //   ],
  // },

  // {
  //   name: "M. Mounssif Adarkaoui",
  //   title: "Director",
  //   company:
  //     "Directorate of Studies and Financial Forecasting, Ministry of Economy, Finance and Administration Reform, Morocco",
  //   image: "Mounssif.jpg",
  //   text: [],
  // },
  // {
  //   name: "Nir Hollander",
  //   title: "Country Manager, Israel, Cyprus & Greece",
  //   company: "Nutanix",
  //   image: "nir.jpg",
  //   text: [],
  // },
  // {
  //   name: "Ohad Arazi",
  //   title: "Chief Executive Officer",
  //   company: "Zebra Medical Vision Ltd",
  //   image: "ohad.jpg",
  //   text: [],
  // },

  // {
  //   name: "Poonam Chawla",
  //   title: "Head of KT Engage and Events & Conferences",
  //   company: "Khaleej Times",
  //   image: "poonam.jpg",
  //   text: [
  //     `Poonam Chawla has over 23 years of experience in the print, digital and television media. As the Head of KT Engage and Conferences and Events , she oversees the editorial and operation functions of content partnerships with clients and launch and execution of B2B and B2C events regionally . Prior to joining Khaleej Times, she was the General Manager of   Bloomberg Businessweek, ME. She has overseen the editorial and operation functions of Bloomberg Businessweek ME’s print and digital editions.In her tenure with diverse Media Titles, Poonam Chawla has successfully driven and accomplished editorial, marketing, and public relations campaigns. She has strong industry expertise, networking, consumer base/ relationship along with track record of producing sustainable revenue and growth in dynamic and changing markets.
  // <br/>
  //     She holds a Master’s Degree from Delhi University, and an MBA from Sardar Patel College of Management Studies. Her interests include reading, travelling and doing Yoga.`,
  //   ],
  // },
];

export const pastspeakers = [
  {
    name: "His Excellency Hamad Buamim",
    title: "President & CEO",
    company: "Dubai Chamber of Commerce & Industry",
    image: "hamad.jpg",
    text: [
      `Holding his current position since 2006, Hamad Buamim is the President & CEO of Dubai Chamber of Commerce & Industry. He is also the Chairman of the Paris-based World Chambers Federation - International Chamber of Commerce (ICC).`,
      `
    Buamim serves as a Board Member of Dubai World, Dubai Islamic Bank PJSC and acts as Chairman of National General Insurance PJSC.`,
      `
    Previously, Buamim served as Chairman of Emirates Financial Services, Emirates NBD Capital as well as a Board Member of the UAE Central Bank, Dubai International Financial Center, Emirates NBD Bank and Network International.`,
      `
    Educated in the USA, Buamim holds MBA with honor in Finance from the University of Missouri, Kansas City. He also obtained a Bachelor of Science with Magna Cum Laude in Electrical Engineering from the University of Southern California, Los Angeles.`,
    ],
  },
  {
    name: "Mike Pompeo",
    title: "Secretary of State",
    company: "United States of America",
    image: "mike.jpg",
    text: [],
  },
  // {
  //   name: "His Excellency Sheikh Abdulla bin Mohammed Al Hamed",
  //   title: "Chairman",
  //   company: "Department of Health – Abu Dhabi",
  //   image: "abdullah.jpg",
  //   text: [],
  // },

  // {
  //   name: "His Excellency Jamal Saif Al Jarwan",
  //   title: "Secretary-General",
  //   company: "The UAE International Investors Council",
  //   image: "jamal.jpg",
  //   text: [
  //     `Mr. Jamal Al Jarwan joined the UAE International Investors Council in November 2014, as Secretary General. Mr. Al Jarwan started his career in Etisalat in 1988. In 1996 he joined Thuraya Telecommunications Company.`,
  //     `
  //   At Thuraya he served as the company’s Chief Commercial Officer, where he designed the overall business strategy and managed the company’s commercial operations division.`,
  //     `
  //   He returned to Etisalat in 2006 to establish and lead the International Investments Division till year 2011 and become the Chief Regional Officer for Asia Region till March 2014.`,
  //     `
  //   Mr. Jamal Al Jarwan served as a board member for several of Etisalat’s international subsidiaries – Thuraya Satellite Telecommunications Company, Etisalat Misr, Ivory Coast, PTCL – Pakistan, Etisalat Lanka, Ufone – Pakistan, Etisalat Afghanistan as well as Chairman of Etisalat Benin.`,
  //     `
  //   Mr. Jamal Al Jarwan Major Achievements are launching Thuraya Mobile Satellite system in 100 countries, securing the third GSM License in Egypt and an Acquisition of an existing third operator in Sri Lanka.`,
  //     `
  //   Mr. Jamal Al Jarwan has a total of 25 years experience in Telecom, Investments and Business Development.`,
  //     `
  //   He holds a Bachelor’s of Science degree in Business Administration from University of Dayton-Ohio in the United States and a degree in Executive Management from IMD business school, Switzerland and degree in Leadership Strategy from Tuck School of Business–Dartmouth University, New Hampshire, United State of America.
  //   `,
  //   ],
  // },
  {
    name: "Avi Berkowitz",
    title:
      "Assistant to the President and Special Representative for International Negotiations",
    company: "United States of America",
    image: "Avi_Berkowitz_Headshot.jpg",
    text: [],
  },

  {
    name: "Izhar Shay",
    title: "Minister of Science and Technology",
    company: "Israel",
    image: "izhar.jpg",
    text: [],
  },
  // {
  //   name: "Zeev Elkin",
  //   title: "Minister of Water Resources",
  //   company: "Israel",
  //   image: "speaker.png",
  //   text: [],
  // },
  {
    name: "Yuli Edelstein",
    title: "Minister of Health",
    company: "Israel",
    image: "yuli.jpg",
    text: [],
  },

  // {
  //   name: "Alon Schuster",
  //   title: "Minister of Agriculture",
  //   company: "Israel",
  //   image: "alon.jpg",
  //   text: [],
  // },

  // {
  //   name: "David Toledano",
  //   title: "President",
  //   company: "Moroccan Association of Building Material",
  //   image: "toledano.jpg",
  //   text: [],
  // },

  {
    name: "David Leffler",
    title: "Director General of the Ministry of Economy and Industry",
    company: "Israel",
    image: "david.jpg",
    text: [],
  },

  {
    name: "Samir Chaturvedi",
    title: "Chief Executive Officer",
    company: "Khalifa Industrial Zone Abu Dhabi",
    image: "samir.jpg",
    text: [
      `Mr Samir Chaturvedi is the Chief Executive Officer (CEO) of Khalifa Industrial Zone Abu Dhabi (KIZAD), the integrated trade and industrial hub of Abu Dhabi. In his role, Mr Chaturvedi oversees KIZAD’s portfolio of assets, activities, operations and growth strategy.
      <br/>Mr Chaturvedi brings over 35 years’ experience working with global organisations in the fields of logistics, shipping, supply chain management, automotive, infrastructure and real estate development. He brings critical skillsets for developing a robust interface between industrial zones and global customers, in addition to developing and executing business strategies.
      <br/>Prior to joining Abu Dhabi Ports, Mr Chaturvedi held the position of Senior Vice President with Economic Zones World (EZW) and Jafza. During his years with EZW and Jafza, he played various roles within the organisation and made a considerable impact in getting these entities to their current position in the industry.
      <br/>Mr Chaturvedi is an alumnus of Harvard University’s Graduate School of Design (GSD), the Chartered Institute of Logistics and Transport (CILT) UK, and Annamalai University in India.
      `,
    ],
  },

  {
    name: "Dr. Ibtesam AlBastaki",
    title: "Director of Public-Private Partnership",
    company: "Dubai Health Authority",
    image: "ibtesam.jpg",
    text: [
      `Dr. Ibtesam Al Bastaki is currently a Director of investment and private partnership at Dubai Health Authority (DHA), and is responsible of development of various Health projects within Emirates of Dubai. In addition to her main role and with 20 years of experience in healthcare she leads, advices and guides on the best practices to ensure a proper holistic expansion in health care industries within UAE. She has also initiated the healthcare investment strategy; she aims to achieve the major six initiatives until 2021 and adopting a PPP concept in healthcare. Previously, Dr. Ibtesam Al Bastaki was heading the health strategy and performance team at Prime Minister Office to aim and achieve the UAE vision 2021. Her role at the organization also led to the introduction of the National Key Performance Indicators and the revamping of the federal health strategy. Dr. Ibtesam’s wealth of expertise also includes her work with Ministry of Health (MOH) and the Dubai Health Authority (DHA). She has had the opportunity to be involved in several projects and fundraising projects within her capacity as the Director of Business Development and Projects at the Dubai Health Authority and has had added more financial value to the organization. Moreover, she has been working closely within her community in different health aspects to increase awareness of various ailments prevalent in society. She also was leading a private ossrganization VPS health care as a Director of Dubai and North of Emirates from 2014-2017 where she had a role in various healthcare projects expansion.`,
    ],
  },

  // {
  //   name: "Essam Al Tamimi",
  //   title: "Chairman",
  //   company: "Al Tamimi & Company",
  //   image: "essam.jpg",
  //   text: [
  //     `Essam Al Tamimi, the Chairman of Al Tamimi & Company holds an LL.M Degree from Harvard University. He has been practicing law for over 35 years in the United Arab Emirates in litigation and arbitration and have been advising clients on commercial matters throughout the region. Essam has authored several books and articles on Setting up Business in the UAE, Litigation and Arbitration. He serves on a number of committees and boards to advance legal practice and has received Lifetime Achievement Awards. His firm Al Tamimi & Company currently has 17 offices in 9 countries. `,
  //   ],
  // },
  {
    name: "Khalid Al Marzooqi",
    title: "Director – Commercial",
    company: "Khalifa Industrial Zone Abu Dhabi (KIZAD)",
    image: "khalid.jpg",
    text: [
      `Khalid Ahmed Mohammed AlMarzooqi is a seasoned business development professional with more
    than ten years of experience.
    Since 2018, Mr AlMarzooqi has been the Director of Commercial Activities at Khalifa Industrial Zone
    Abu Dhabi (KIZAD), where he oversees KIZAD’s real estate division, Polymers Park, the business
    development section and the international business section. His remit includes creating and
    developing sales channels, strengthening the presence of small and medium-sized enterprises
    (SMEs) in the zone, managing mega industrial projects and enhancing relations with government
    agencies.
    From 2013-2018, Mr AlMarzooqi was a senior sales manager at the Jebel Ali Free Zone (JAFZA),
    responsible for the Asia Pacific region, where he managed six overseas JAFZA offices. During his
    time with JAFZA, Mr AlMarzooqi attracted a raft of companies to the free zone, including several
    Global Fortune 500 companies. While at JAFZA, Mr AlMarzooqi also held the position of acting head
    of the business development department at DP World, where he managed KPIs, the budget and was
    the regional manager for Europe and America.
    Mr AlMarzooqi holds a Master of International Business from Wollongong University in Dubai and a
    Bachelor of Science in Business Administration from the American University of Sharjah.`,
    ],
  },

  // {
  //   name: "Dr. Hamed Ali Al-Hashemi",
  //   title: "Director of Strategy",
  //   company: "Department of Health Abu Dhabi",
  //   image: "hashemi.jpg",
  //   text: [],
  // },

  {
    name: "Dr. Mohammed AlRedha",
    title: "Director of Projects Management Office",
    company: "Dubai Health Authority",
    image: "redha.jpg",
    text: [
      `Dr. Mohammed AlRedha, Director of Projects Management Office, Dubai Health AuthorityDr. Al Redha is a graduate and postgraduate of the Royal College of Surgeons in Ireland and holds a Masters Degree (MSc) in Healthcare Management. He Chairs the Emirates Health Informatics Society, a scientific and educational platform dedicated for promoting excellence in Heath Informatics within the Emirates Medical Association. He is the Director of the Project Management Office & Health Informatics and Smart Health Department at the Dubai Health Authority. In 2016, he also established the DHA’s Accelerator Office within Dubai Future Foundation. Dr. Mohammad was a Research Fellow in Clinical Informatics at the Division of Clinical Informatics, Harvard Medical School in Boston (2008-2009). Prior to joining the field of informatics, Dr. Mohammad was the Chief Operating Officer at Rashid Hospital – a 600 bed trauma facility serving Dubai since 1973. He is also a member of the Mohammed Bin Rashid Program for Leadership Development Programme and was a Fellow at the Dubai School of Government.    `,
    ],
  },

  {
    name: "Vaman Vassudev Kamat",
    title: "Editor in Chief",
    company: "Khaleej Times",
    image: "vaman.jpg",
    text: [],
  },
  // {
  //   name: "Dr. Raphael Nagel",
  //   title: "Founder and Chairman of The Board",
  //   company: "The Abrahamic Business Circle",
  //   image: "raphael.jpg",
  //   text: [
  //     `A man of his time, Raphael Nagel is a renowned investor, a lobbyist and a philanthropist. In 2020, he was
  //     awarded by the Indian financial magazine Business APAC as one of the leading Investment Gurus of the said
  //     year. Besides, he was also awarded a prize by the Business Worldwide Magazine (BWM) CEO Awards as the
  //     UAE’s Financial Services CEO, 2020.<br/>
  //     His tireless entrepreneurial spirit has led him to chair the Board of The Abrahamic Business Circle, an
  //     innovative global interfaith business dialogue forum based in the United Arab of Emirates. Before devoting
  //     himself to business and entrepreneurship, Dr. Nagel’s academic background in Economics and Law has been
  //     forged in university centers in Germany, the United Kingdom and Spain.<br/>
  //     Additionally, he is the co-founder of a Spanish investment bank specialized on cross-border transactions and
  //     debt restructuring. In 2016, he went above and beyond the call of duty to help others and he founded a
  //     Charity Trust in order to combat the social exclusion caused by over-indebtedness and give support to basic
  //     overall financial education and awareness.<br/>
  //     Amongst his many occupations, he found the time to author and edit several books on highly relevant issues
  //     such as Economics and Geopolitics. Over the years, Dr. Nagel has been an energetic member of various
  //     important working groups as well as Senior Advisor to major companies, both within and beyond Europe.
  //     Highly reputed as an Independent Consultant on Economic Policy, throughout his long career he has advised
  //     a number of public bodies and Governments all over the world always aiming towards global excellence.`,
  //   ],
  // },

  {
    name: "Maayan Hoffman",
    title: "News Editor",
    company: "Jerusalem Post",
    image: "placeholder.jpg",
    text: [` `],
  },
  {
    name: "Lahav Harkov",
    title: "Diplomatic Correspondent",
    company: "Jerusalem Post",
    image: "placeholder.jpg",
    text: [` `],
  },
  {
    name: "Steve Linde",
    title: "Editor",
    company: "Jerusalem Report",
    image: "speaker.png",
    text: [` `],
  },
];

export const sponsors = [
  // {
  //   name: "Kizad",
  //   pageLink: "Kizad",
  //   type: "LEAD   SPONSOR",
  //   image: "kizad.png",
  //   text: [
  //     `Established in 2010 and operational since 2012, Khalifa Industrial Zone Abu Dhabi (KIZAD) a
  //   subsidiary of Abu Dhabi Ports, is the largest industrial and free trade zone in the MENA region.
  //   KIZAD offers the most competitive business setup solutions and world-class infrastructure
  //   facilities in the UAE.<br/>
  //   KIZAD is located strategically between Abu Dhabi and Dubai, the two most important economic
  //   centres of the region. With the deep-water Khalifa Port as its maritime gateway, KIZAD is Abu
  //   Dhabi's integrated trade, logistics and industrial hub facilitating sustainable growth in Food,
  //   Polymers, Metals, Construction, Pharmaceuticals, Logistics, Oil & Gas, among others.
  //   Spread over 410 KM 2 , KIZAD is one of the world's largest industrial zones and is home to over
  //   600 companies. Setting up at KIZAD enables businesses to benefit from the strategic location,
  //   faster access to markets, low set up and operational costs, flexibility in scale of operations and
  //   efficient investor support services.<br/>
  //   KIZAD offers both Free Zone and Domestic Economic Zone facilities for investors. The Free Zone
  //   jurisdiction allows 100% foreign ownership in companies, while goods produced in the
  //   Domestic Economic Zone can be certified &quot;MADE IN UAE&quot; by the relevant authorities and can
  //   avail duty-free status in the GCC region.<br/>
  //   KIZAD can accommodate industries of any scale due to the meticulous master plan of the industrial park.
  //   Because KIZAD comprises multiple industrial clusters, land allocation is based on grouping certain
  //   industries and service providers based on how best they can benefit one another. Value is created by
  //   leveraging anchor tenants and facilitating synergies and operational efficiencies; investors can utilise
  //   products produced by another within the zone. This gathering of collective expertise also creates a
  //   business environment that fosters research, growth and the development of skills and talents.`,
  //   ],
  //   link: "https://www.kizad.ae/",
  //   size: "100%",
  //   grid: 3,
  // },
  // {
  //   name: "Nutanix",
  //   pageLink: "Nutanix",
  //   type: "STRATEGIC SPONSOR",
  //   image: "nutanix.png",
  //   text: [],
  //   link: "https://www.nutanix.com/",
  //   size: "100%",
  //   grid: 3,
  // },
  {
    name: "World Jewish Congress",
    pageLink: "wjc",
    type: "LEAD SPONSOR",
    image: "jwc.jpg",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "LOBSER",
    pageLink: "LOBSER",
    type: "GOLD SPONSOR",
    image: "lobser.jpg",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    // title: true,
  },
  {
    name: "ourcrowd",
    pageLink: "ourcrowd",
    type: "LUNCH SPONSOR",
    image: "ourcrowd.jpg",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    // title: true,
  },
  {
    name: "Zebra Medical Vision",
    pageLink: "Zebra-Medical-Vision",
    type: "GOLD SPONSOR",
    image: "zebra.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    // title: true,
  },
  {
    name: "Mekorot",
    pageLink: "Mekorot",
    type: "LEAD SPONSOR",
    image: "mekorot.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "Sylvan",
    pageLink: "sylvan",
    type: "LEAD SPONSOR",
    image: "sylvan.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "UIBC",
    pageLink: "UIBC",
    type: " ",
    image: "uibc.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    title: true,
  },
  {
    name: "Steinreich",
    pageLink: "Steinreich",
    type: " ",
    image: "steinreich.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    title: true,
  },
  {
    name: "Abrahamic",
    pageLink: "Abrahamic",
    type: " ",
    image: "abrahamic.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    title: true,
  },
  {
    name: " Ajman Free Zone",
    pageLink: "ajman",
    type: "NETWORKING SPONSOR",
    image: "afz.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "ECI",
    pageLink: "ECI",
    type: "NETWORKING SPONSOR",
    image: "eci.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "RAKEZ",
    pageLink: "RAKEZ",
    type: "NETWORKING PARTNER",
    image: "rake.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
  },
  // {
  //   name: "Dubai Silicon Oasis Authority",
  //   pageLink: "dso",
  //   type: "NETWORKING PARTNER",
  //   image: "dso.jpg",
  //   text: [
  //     `Dubai Silicon Oasis Authority, a wholly-owned entity of the Dubai government, is an integrated free zone technology park that was established with the Mission to “facilitate and promote modern technology-based industries”. DSO’s master-planned community spans 7.2 square-kilometers of state-of-the-art office towers, R&D facilities and industrial zones, educational institutions, luxury apartments, villas, hotels, and healthcare centers. DSO also offers a comprehensive range of lifestyle options that shape its dynamic commercial and social environment. An enabling and nurturing hub for technology-based enterprises, DSO’s unrivalled package of incentives include: 100% ownership, and advanced-IT-infrastructure that allow businesses to commence immediate operations among many others.`,
  //   ],
  //   link: "https://www.dsoa.ae/",
  //   size: "100%",
  //   grid: 3,
  // },
  {
    name: "flydubai",
    pageLink: "flydubai",
    type: "OFFICIAL AIRLINE PARTNER",
    image: "flydubai.jpg",
    text: [
      `From its home in Dubai, flydubai has created a network of more than 90
    destinations and over the next decade the airline will see its fleet grow by up to
    237 aircraft. Since commencing operations in June 2009, flydubai has been
    committed to removing barriers to travel, creating free flows of trade and tourism
    and enhancing connectivity between different cultures across its ever-expanding
    network.<br/>
    flydubai has marked its journey with a number of milestones that represent the
    scale of the ambition planned for the airline:
    <br/>
     An expanding network: Created a network of more than 90 destinations in 48
    countries across Africa, Central Asia, the Caucasus, Central and South-East
    Europe, the GCC and the Middle East, and the Indian Subcontinent.
    <br/>
     Serving underserved markets: Opened up more than 65 new routes that did
    not previously have direct air links to Dubai or were not served by a UAE national
    carrier from Dubai.
    <br/>
     An efficient single fleet-type: Operates a single fleet-type of more than 50
    Boeing 737 aircraft. 
    <br/>
     Record-breaking orders: Placed the largest single-aisle aircraft orders in the
    region at the 2013 and 2017 editions of the Dubai Airshow.
    <br/>
     Enhancing connectivity: Carried more than 75 million passengers since it
    began operations in 2009.
    For all our latest news, please visit the flydubai Newsroom.`,
    ],
    link: "https://www.flydubai.com/en/",
    size: "100%",
    grid: 3,
  },
  {
    name: "Haim Bibas",
    pageLink: "Haim Bibas",
    type: "  ",
    image: "haim.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    title: true,
  },
  {
    name: "Alive",
    pageLink: "Alive",
    type: "  ",
    image: "alive.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    title: true,
  },
  {
    name: "Port Ltv Residence",
    pageLink: "Port",
    type: "  ",
    image: "port.png",
    text: [],
    link: "",
    size: "100%",
    grid: 3,
    title: true,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    image: "1.jpg",
    link: "https://www.khaleejtimes.com/news/free-zones-make-uae-a-favoured-trade-hub-for-business",
    title: "Free zones make UAE a favoured trade hub for business",
  },
  {
    image: "2.jpg",
    link: "https://www.khaleejtimes.com/news/free-zones-have-key-role-to-play-in-post-pandemic-recovery",
    title: "Free zones have key role to play in post-pandemic recovery",
  },
  {
    image: "3.jpg",
    link: "https://www.khaleejtimes.com/coronavirus-pandemic/india-uae-ties-strengthened-during-covid-19-pandemic-ambassador-pavan-kapoor",
    title:
      "India-UAE ties strengthened during Covid-19 pandemic: Ambassador Pavan Kapoor",
  },
  {
    image: "4.jpg",
    link: "https://www.khaleejtimes.com/region/mena/abraham-accords-is-not-history-it-is-the-future-dr-ali-al-nuami",
    title: "Abraham Accords is not history, it is the future: Dr Ali Al Nuami",
  },
  {
    image: "5.jpg",
    link: "https://www.khaleejtimes.com/region/mena/two-state-solution-can-bring-peace-in-mideast-former-israeli-pm",
    title: "Two-state solution can bring peace in MidEast: Former Israeli PM",
  },
  {
    image: "6.jpg",
    link: "https://www.khaleejtimes.com/news/live-streaming-global-investment-forum",
    title: "Live streaming: Global Investment Forum",
  },
  {
    image: "7.jpg",
    link: "https://www.khaleejtimes.com/news/export-credit-agencies-can-accelerate-economic-recovery",
    title: "Export credit agencies can accelerate economic recovery",
  },
  {
    image: "8.jpg",
    link: "https://www.khaleejtimes.com/news/international-cooperation-key-to-stronger-post-covid-economy-uae-minister",
    title:
      "International cooperation key to stronger post-Covid economy: UAE minister",
  },
  {
    image: "9.jpg",
    link: "https://www.khaleejtimes.com/news/innovation-will-drive-the-uaes-evolution-top-official",
    title: "Innovation will drive the UAE's evolution: Top official",
  },
  {
    image: "10.jpg",
    link: "https://www.jpost.com/j-spot/wjc-pres-lauder-addresses-global-investment-forum-arabs-jews-want-peace-669836",
    title:
      "WJC pres. Lauder addresses Global Investment Forum: Arabs, Jews want peace",
  },
  {
    image: "11.jpg",
    link: "https://www.jpost.com/israel-news/port-tlv-project-highlighted-at-dubai-investment-forum-669883",
    title: "Port TLV Project highlighted at Dubai Investment Forum",
  },
  {
    image: "12.jpg",
    link: "https://www.jpost.com/middle-east/ourcrowd-ceo-uae-is-gateway-to-half-of-the-world-669918",
    title: "OurCrowd CEO: UAE is gateway to ‘half of the world",
  },
  {
    image: "13.jpg",
    link: "https://www.jpost.com/israel-news/israel-and-the-uae-should-have-a-combined-space-program-morris-kahn-says-669871",
    title: "Morris Kahn: Israel on the moon in 2024",
  },
  {
    image: "14.jpg",
    link: "https://www.jpost.com/middle-east/uae-boasts-the-advantages-of-its-free-trade-zone-669888",
    title: "UAE boasts the advantages of its free trade zone",
  },
  {
    image: "15.jpg",
    link: "https://www.jpost.com/israel-news/director-of-tourism-at-jda-the-world-is-choosing-jerusalem-669865",
    title: "Director of tourism at JDA: ‘The world is choosing Jerusalem",
  },
  {
    image: "16.jpg",
    link: "https://www.jpost.com/middle-east/moroccan-experts-morocco-is-a-growth-lever-in-africa-669859",
    title: "Moroccan experts: Morocco is a growth lever in Africa",
  },
  {
    image: "17.jpg",
    link: "https://www.jpost.com/jpost-tech/mekorot-ceo-its-time-for-low-cost-quality-water-and-we-can-provide-it-669887",
    title:
      "Mekorot CEO: It's time for low-cost quality water, and we can provide it",
  },
  {
    image: "18.jpg",
    link: "https://www.jpost.com/health-science/advancing-health-technology-at-the-global-investment-forum-669537",
    title: "Advancing health technology at the Global Investment Forum",
  },
  {
    image: "19.jpg",
    link: "https://www.jpost.com/middle-east/noveto-systems-emiratis-are-very-open-to-innovation-669901",
    title: "Noveto Systems: Emiratis are very open to innovation",
  },
];
