import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";

function TextGrid() {
  let pageHeader = React.createRef();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#efefef",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-g text-center section-title my-4">
                <b>Global Investment Forum</b>
              </h1>
            </Col>
            <Col lg={6} className="align-self-center">
              <h3 className="text-dark text-400">
                The Global Investment Forum, organized by the Khaleej Times
                newspapers, will bring together businessmen, political leaders
                and opinion makers from across the globe for a conference in
                Dubai in June 2021. The conference will focus on business and
                diplomatic opportunities between Israel, the Gulf, the Far East.
                The two biggest newsrooms will bring together the most
                influential, dynamic and innovative figures in investing for
                in-depth explorations of the challenges and opportunities posed
                by a rapidly changing landscape.
                <br />
                <br />
                <Button
                  className="px-3 py-2 rounded-0"
                  color="primary"
                  style={{ minWidth: "200px" }}
                  href="/register"
                >
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    REGISTER INTEREST
                  </p>
                </Button>
              </h3>
            </Col>
            <Col lg={6} className="text-center">
              {/* <img
                src={require(`assets/images/textgrid.jpg`)}
                width="80%"
                alt="Finnovex"
              /> */}

              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  title="live"
                  class="embed-responsive-item"
                  src="https://www.youtube.com/embed/rcEuTqFvSjg"
                  allowfullscreen
                ></iframe>
              </div>
              <p className="mt-2 text-left">
                UAE-Israel Business Summit by Khaleej Times and The Jerusalem
                Post | 13th January 2021
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt=""
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.darktrace.com/en/", image: "9.jpg" },
  // { link: "https://www.sas.com/en_ae/home.html", image: "10.jpg" },
];

export default TextGrid;
