import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function SponsorPage({ sponsors }) {
  console.log(sponsors);
  const rows = [
    ["LEAD SPONSOR"],
    ["LUNCH SPONSOR", "NETWORKING SPONSOR", "NETWORKING PARTNER"],
    ["  "],
    ["OFFICIAL AIRLINE PARTNER", " "],
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "#0A1332",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  // <Col lg={4} className={"text-center"}>

                  //   <img
                  //     src={require(`assets/sponsors/${s.image}`)}
                  //     alt={s.name}
                  //     width={s.size}
                  //     style={{ borderRadius: "0" }}
                  //   />
                  // </Col>
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                      <h3
                        className="text-white text-400 text-uppercase text-center pb-3"
                        style={{
                          margin: 0,
                          padding: 0,
                          paddingTop: s.title && "20px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: !s.title ? s.type : "",
                        }}
                      ></h3>
                      <a href={`/sponsors/${s.pageLink}`}>
                        <Card
                          className="mx-auto rounded-0"
                          style={{ borderRadius: 0, maxWidth: s.size }}
                        >
                          <img
                            src={require(`assets/sponsors/${s.image}`)}
                            alt={s.name}
                            width="100%"
                            className="rounded-0"
                          />
                        </Card>
                      </a>
                    </ScrollAnimation>
                  </Col>
                ))
              )}
            </Row>
          ))}

          {/* {rows.map(
            <Row>
              {Object.keys(sponsors).map((sponsor, index) => (
                <React.Fragment key={index}>
                  <Col lg={12}>
                    <h2 className="text-center pb-4 text-white text-uppercase">
                      <b>
                        {sponsors[sponsor].length > 1 ? `${sponsor}s` : sponsor}
                      </b>
                    </h2>
                  </Col>
                  {sponsors[sponsor].map((data, i) => (
                    <Col lg={4} className={"ml-auto mr-auto"} key={i}>
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <a href={`/sponsors/${data.pageLink}`}>
                          <Card
                            className="mx-auto"
                            style={{ borderRadius: 0, maxWidth: data.size }}
                          >
                            <img
                              src={require(`assets/sponsors/${data.image}`)}
                              alt={data.name}
                              width="100%"
                            />
                          </Card>
                        </a>
                      </ScrollAnimation>
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </Row>
          )} */}
        </Container>
      </div>
    </>
  );
}

export default SponsorPage;
