import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://uae-israel-business-summit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              Dubai reinforced its preeminence as a destination of choice for
              foreign direct investment in 2020 by recording an FDI inflow of
              Dh24.7 billion, creating an estimated 18,325 new jobs despite the
              harsh global epidemic challenges.
              <br />
              <br /> Dubai ranks first in the Middle East and North Africa
              region and fourth globally as a magnet for greenfield FDI capital,
              according to the Financial Times’ fDi Markets, the leading global
              source of data on greenfield FDI.
              <br />
              <br /> Dubai also achieved a record global market share in
              greenfield FDI projects, attracting 2.1 per cent of all such
              projects in 2020, exceeding the 2.0 per cent mark for the first
              time, as it continued to lead regional and global FDI locations in
              facilitating global business growth and expansion.
              <br />
              <br /> Sheikh Hamdan bin Mohammed bin Rashid Al Maktoum, Crown
              Prince of Dubai and Chairman of the Executive Council, said
              Dubai’s regional leadership and global prominence in FDI
              attraction is the result of the great vision of His Highness
              Sheikh Mohammed bin Rashid Al Maktoum, Vice-President and Prime
              Minister of the UAE and Ruler of Dubai. “His vision laid solid
              foundations for Dubai’s emergence as a smart and sustainable city
              of the future, the main gateway to regional growth markets, and a
              pivotal hub in the global economy.”
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
