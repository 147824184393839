import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

import Slider from "react-slick";

function News({ news }) {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="title">
                <h1 className="text-center section-title text-g text-uppercase">
                  <b>NEWS</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={11} className={"ml-auto mr-auto"}>
              <Slider {...settings}>
                {news.map((n, index) => (
                  <Container fluid key={index}>
                    <Row>
                      <Col lg={12}>
                        <Card
                          className=""
                          style={{
                            borderRadius: 0,
                          }}
                        >
                          <Container fluid>
                            <Row>
                              <Col
                                lg={12}
                                style={{ padding: 0 }}
                                className="align-self-center"
                              >
                                {n.iframe ? (
                                  <div class="embed-responsive embed-responsive-16by9">
                                    <iframe
                                      src="//players.brightcove.net/590066524001/17afba39-9902-4343-a46b-498c3530b08e_default/index.html?videoId=6256963335001"
                                      allowfullscreen
                                      frameborder={0}
                                      title="jpost"
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={require(`assets/news/${n.image}`)}
                                    alt="meltwater"
                                    width="100%"
                                    style={{ borderRadius: 0 }}
                                  />
                                )}
                              </Col>
                              <Col
                                lg={12}
                                className=""
                                style={{
                                  backgroundColor: "#051245",
                                  padding: "1rem",
                                }}
                              >
                                <h4
                                  className="text-white"
                                  style={{ margin: 0, padding: 0 }}
                                >
                                  {n.title}
                                </h4>
                                <br />
                                <h6
                                  className="text-white"
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    textTransform: "none",
                                  }}
                                >
                                  {n.short_description}{" "}
                                  <Button
                                    className="btn"
                                    color="info"
                                    size="sm"
                                    href={n.link}
                                    outline
                                    style={{ minWidth: "100%", color: "#fff" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    More
                                  </Button>
                                </h6>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={{ marginTop: "2rem" }}>
            <Col lg={12} className={"text-center"}>
              <Button href="/media/industry-news" className="btn" color="primary" size="lg">
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  VIEW ALL
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;
