import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function TextGrid({ speakers }) {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" +
            require("assets/images/background.jpg") +
            "), " +
            "url(" +
            require("assets/images/shapebg.png") +
            ")",

          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="section section-with-space p-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="py-2 justify-content-center">
            {/* <Col sm={6} className="mb-auto mt-auto border-right">
              <img
                src={require(`assets/images/logo/logo.png`)}
                // style={{ maxWidth: "300px" }}
                width="100%"
                alt="UIBS"
              />
            </Col> */}
            {/* <Col sm={6} className="mb-auto mt-auto ">
              <h1
                className="text-700 text-white text-center text-uppercase"
                style={{
                  fontSize: size ? "1.55rem" : "1rem",
                }}
              >
                Wednesday, June 6, 2021
              </h1>
              <h1
                className="text-700 text-white text-center text-uppercase p-3"
                style={{
                  fontSize: size ? "1.75rem" : "1rem",
                  background:
                    "linear-gradient(129deg, rgba(51,43,132,1) 12%, rgba(0,168,226,1) 77%)",
                }}
              >
                HEAR FROM EMINENT LEADERS
              </h1>
            </Col> */}
            <Col lg={12}>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  title="live"
                  class="embed-responsive-item"
                  src="https://www.youtube.com/embed/rcEuTqFvSjg"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>

          <Row>
            {speakers.map((speaker, index) =>
              size ? (
                <Col className="ml-auto mr-auto px-1" key={index}>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    duration={0.5}
                  >
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ) : (
                <Col className="ml-auto mr-auto px-1" xs={12} key={index}>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    duration={0.5}
                  >
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              )
            )}
          </Row>
          <Row>
            <Col lg={12} className="py-5">
              <h3 className="text-white text-400">
                Following the signing of the historic Abraham Accords, Khaleej
                Times and The Jerusalem Post, the two largest English-language
                media organizations in Israel and the UAE, are coming together
                to launch the Global Investment Forum, in association with
                UAE-Israel Business Council.
                <br />
                <br />
                The web broadcast discusses and highlights the bilateral
                business opportunities between the countries.
                <br />
                <br />
                The initiative represents a new dawn in the multifaceted
                economic relationship between the two countries and will bring
                together top government officials, business leaders, and
                industry experts from across different sectors, including
                healthcare, hospitality, defense, security, trade and
                technology.
              </h3>
              {/* <Button
                className="px-3 py-2 mt-2 rounded-0"
                color="primary"
                // style={{ width: "99%" }}
                href="/"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  CONFIRMED SPEAKERS
                </p>
              </Button>{" "} */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

function SpeakerCard({ speaker }) {
  return (
    <Container className="mb-3">
      <Row>
        <Col xs={12} className="px-0">
          <div className="card-avatar px-3 pt-3">
            <img
              src={require(`assets/speakers/${speaker.image}`)}
              width="100%"
              alt="..."
              style={{ borderRadius: 0, maxWidth: "400px" }}
            />
          </div>
        </Col>
        <Col xs={12} className="text-center text-">
          <h3
            className="text-white text-600 p-2"
            style={{
              background:
                "linear-gradient(129deg, rgba(48,0,14,1) 4%, rgba(243,0,16,1) 64%)",
            }}
          >
            {speaker.name}
          </h3>

          <h4 className="text-white m-0">{speaker.title}</h4>
          <h4 className="text-white text-600  m-0 d-block px-0">
            {speaker.company}
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

export default TextGrid;
