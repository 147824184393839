import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://uae-israel-business-summit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              Negotiation skills of diplomats were key in the moments leading up
              to the UAE-Israel Abraham Accords, said two main driving forces
              behind the deal inked in September.
            </p>
            <br />
            <br />
            <p>
              At a virtual event hosted by the Emirates Diplomatic Academy (EDA)
              in Abu Dhabi, Avi Berkowitz, assistant to the US President and
              special representative for international negotiations, along with
              Yousef Al Otaiba, UAE Ambassador to the US and Minister of State,
              gave an insight into the hard work done behind the scenes.
            </p>
            <br />
            <br />
            <p>
              &ldquo;There were a lot of countries that were thinking of ways to
              improve their relationship with Israel, but there are a lot of
              risks in that and it takes a lot of courage. The UAE and the
              leaders of the UAE took courage in taking that step, which
              inspired other countries also to show courage,&rdquo; Berkowitz
              said while taking part in the &lsquo;EDA Next 50 Talks&rsquo;
              series titled &lsquo;The Abraham Accords and US Foreign
              Policy&rsquo;.
            </p>
            <br />
            <br />
            <p>
              Berkowitz pointed out that when it comes to negotiating, diplomats
              must consider what brings value to their country.
            </p>
            <br />
            <br />
            <p>
              &ldquo;When negotiations take place, be comfortable about being
              uncomfortable for a significant period of time. Keep in mind that
              you might not get a resolution for something, even though your
              intentions are in the right place,&rdquo; he told EDA&rsquo;s
              professors, researchers and future diplomats.
            </p>
            <br />
            <br />
            <p>
              Berkowitz added that from the moment the Abraham Accords were
              signed, the vast majority of Israelis preferred the outcome of the
              agreement and &ldquo;forgot about the annexation&rdquo; of the
              West Bank territory.
            </p>
            <br />
            <br />
            <p>
              &ldquo;We believe this deal is a big game changer for the region.
              The UAE shows a great model for tolerance. I couldn&rsquo;t be
              prouder to work with the UAE, as a Jewish person and as an
              American. I think the Abraham Accords put the UAE in a new league,
              it took tremendous courage and people are now approaching the UAE
              in a different light,&rdquo; he added.
            </p>
            <br />
            <br />
            <p>
              <strong>&lsquo;Be a good negotiator&rsquo;</strong>
            </p>
            <br />
            <br />
            <p>Al Otaiba noted the Abraham Accords were a win for diplomacy.</p>
            <br />
            <br />
            <p>
              &ldquo;Diplomacy gets the credit for achieving something that the
              region desperately needed. The Abraham Accords set off a chain
              reaction of events, where the full outcome will appear much later.
              We have proven that we are not the only ones who can do this
              successfully, but three other countries proved this as well,
              within a matter of four months,&rdquo; he added.
            </p>
            <br />
            <br />
            <p>
              He stressed on the importance for every diplomat to be a good
              negotiator.
            </p>
            <br />
            <br />
            <p>
              &ldquo;In most negotiations, you have to gain something and give
              something up, but this was not the case here, we both wanted the
              same outcome. If your outcome is ultimately a win-win deal, there
              is very little tension.&rdquo;
            </p>
            <br />
            <br />
            <p>
              Al Otaiba told the EDA&rsquo;s trainee diplomats to focus on
              building trust and transparent relationships in their diplomatic
              career: &ldquo;Focus on your skills set. It is important to build
              strong relationships that are built on trust, credibility and
              transparency. If you have these relationships, you will do well
              regardless of where you are posted. Try to build your
              relationships on solid ground. Separate your personality and
              beliefs from your analysis and assessments &ndash; this is key for
              all diplomats.&rdquo;
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
