import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";
import { sponsorsPage } from "./content";
import Testimonials from "components/Testimonials.js";

import WhoAttend from "components/WhoAttend";
import PriceCard from "components/PriceCard.js";

function AboutPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="ATTEND AIF2020" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <WhoAttend />
      </div>
      <PriceCard />
      <Testimonials />
      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default AboutPage;
