import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function News({ news }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            {news.map((n, index) => (
              <Col lg={3}>
                <Container fluid key={index}>
                  <Row>
                    <Col lg={12}>
                      <Card
                        className=""
                        style={{
                          borderRadius: 0,
                        }}
                      >
                        <Container fluid>
                          <Row>
                            <Col
                              lg={12}
                              style={{ padding: 0 }}
                              className="align-self-center"
                            >
                              {n.iframe ? (
                                <div class="embed-responsive embed-responsive-16by9">
                                  <iframe
                                    src={n.iframe}
                                    allowfullscreen
                                    frameborder={0}
                                    title="jpost"
                                  />
                                </div>
                              ) : (
                                <img
                                  src={require(`assets/news/${n.image}`)}
                                  alt="meltwater"
                                  width="100%"
                                  style={{ borderRadius: 0 }}
                                />
                              )}
                            </Col>
                            <Col
                              lg={12}
                              className=""
                              style={{
                                backgroundColor: "#051245",
                                padding: "1rem",
                              }}
                            >
                              <h4
                                className="text-white"
                                style={{ margin: 0, padding: 0 }}
                              >
                                {n.title}
                              </h4>
                              <br />
                              <h6
                                className="text-white"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  textTransform: "none",
                                }}
                              >
                                {n.short_description}{" "}
                                <Button
                                  className="btn"
                                  color="info"
                                  size="sm"
                                  href={n.link}
                                  outline
                                  style={{ minWidth: "100%", color: "#fff" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  More
                                </Button>
                              </h6>
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;
