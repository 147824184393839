import React from "react";

import { Container, Row, Col, Card, CardImg } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#efefef",
        }}
        className="section section-with-space border-top border-bottom py-0 pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1
                className="text-center section-title pt-3"
                style={{ color: "#051245" }}
              >
                <b>WHY ATTEND</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content3.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card className="p-3">
                    <CardImg
                      src={require(`assets/images/icons/${data.image}`)}
                      style={{ maxWidth: "100px" }}
                      className="mx-auto"
                    />
                    <hr />
                    <h3 className="text-700 pt-3">{data.title}</h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title:
      "The Global Investment Forum is one of the premier business gatherings in the region.",
    image: "42.png",
  },
  {
    title:
      "Hear from & network with the most influential line-up of government officials, industry elites and investment executives from Israel, the Gulf, the Far East.",
    image: "41.png",
  },
  {
    title:
      "The first of its kind conference that will set the tone for business and diplomatic opportunities between Israel, the Gulf, the Far East",
    image: "40.png",
  },
];

export default WhyAttend;
