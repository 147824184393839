import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://uae-israel-business-summit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              The bolstering relationship between the UAE and Israel was
              underpinned at a ceremony on Saturday where the visiting
              Israel&rsquo;s Sephardi Chief Rabbi prayed especially to bless the
              liberal rulers of the country who allow different faiths to be
              practiced in the UAE.
            </p>
            <br />
            <br />
            <p>
              During the socially distanced ceremony held at the Jewish
              Community Centre (JCC) in Dubai, Chief Rabbi Yitzhak Yosef
              expressed his appreciation to the Emirati royal family and the
              government, for the warm relations, care for the Jewish community
              and the thriving ties between Israel and the UAE, following the
              historic Abraham Accords.
            </p>
            <br />
            <br />
            <p>
              After the conclusion of the Sabbath, he said: &ldquo;The survival
              of a Jewish community depends on its adherence to Torah values
              (God's revealed teaching or guidance for the Jewish people) and
              Torah learning."
            </p>
            <br />
            <br />
            <p>
              The ceremony also marked the first certification granted to a
              synagogue in the Emirates, the Beit Tefillah Synagogue in Abu
              Dhabi, and invested Rabbi Levi Duchman as rabbi of the Emirati
              Jewish community.
            </p>
            <br />
            <br />
            <p>
              Lauding the work of Rabbi Duchman, Chief Rabbi Yosef pointed out:
              &ldquo;The Jewish community in the Emirates is fortunate to have a
              rabbi that not only teaches Torah, but is working to grow the
              community and its institutions."
            </p>
            <br />
            <br />
            <p>
              Rabbi Duchman thanked him and said: &ldquo;We are fortunate to
              have been able to discuss with him the strengthening of the
              community and its facilities and receive his guidance on a number
              of issues prevalent to Jewish traditions and customs. We hope to
              welcome him back very soon and very often.&rdquo;
            </p>
            <br />
            <br />
            <p>
              <strong>
                <em>'Treaty truly futuristic'</em>
              </strong>
            </p>
            <br />
            <br />
            <p>
              The evening was filled with cheer and witnessed the customary
              ritual involving lighting a special Havdalah candle, blessing a
              cup of wine and smelling sweet spices.
            </p>
            <br />
            <br />
            <p>
              The brief ceremony - Havdalah (that literally means separation or
              distinction) - marked the end of Shabbath and the room echoed with
              Havdalah blessings as attendees collectively recited their
              dedications in Hebrew.
            </p>
            <br />
            <br />
            <p>
              Excited attendees in the room included Israeli expats in the UAE
              and Jewish tourists who had travelled to the UAE not just from
              Israel but from all across the world.
            </p>
            <br />
            <br />
            <p>
              Ron Sarfati, a 17-year-old who had travelled all the way to the
              UAE from France, said: &ldquo;I think this accord is brilliant and
              paves the way for a strong bilateral relationship. Since the past
              few years, the Jewish community wanted to be friends with the
              Muslim community here and I think this peace treaty is truly
              futuristic. This will benefit not only the Jewish community but
              entire communities living in the emirate.&rdquo;
            </p>
            <br />
            <br />
            <p>
              Tsahitt Rofe, a marketing professional and Israeli citizen, echoed
              similar sentiments. He said: &ldquo;It feels very good to be
              coming to Dubai. The journey from Israel to Dubai is only three
              hours. The two countries will now do business together that will
              benefit people of both the nations. I have seen almost all the
              major landmarks in the city, including Burj Khalifa. It&rsquo;s a
              very safe city and it's lovely. Many things here are quite
              different from Israel, but I do see a streak of similarity as
              well.&rdquo;
            </p>
            <br />
            <br />
            <p>
              Meanwhile, an old timer in the UAE and a Dubai resident Mark
              Scolaire averred he is proud to be a part of this historic start.
            </p>
            <br />
            <br />
            <p>
              He pointed out: &ldquo;I have spent 13 years here. We have always
              said that Arabic culture and Jewish culture are all the same.
              It&rsquo;s only political things that divide us but now with this
              peace treaty and getting to interact with more Emiratis, we
              realise the affinity of our cultures. This is bringing everyone
              together and it&rsquo;s this unity that we hope will be the same
              going forward for many years. This is just the start of it and I
              am filled with pride to participate in all this.&rdquo;
            </p>
            <br />
            <br />
            <p>
              He added: &ldquo;I even started learning Hebrew recently. I was
              completely disconnected from the Jewish community for many years.
              I have started learning the language and picking up the letters
              now. This initiative between the two countries has made me a
              student again, as I am learning new things even about my own
              culture, while living here in the emirate.&rdquo;
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
