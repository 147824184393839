import React from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";
import Stats from "components/Stats.js";

import SpeakerCard from "components/SpeakerCard.js";
import Sponsor from "components/Sponsor.js";
import Agenda from "components/Agenda.js";
import { news, speakers, sponsorsPage } from "./content";
import WhyAttend from "components/WhyAttend";
import News from "components/News";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <Stats />
          <TextGrid />
          <section id="speakers" />
          <SpeakerCard speakers={speakers} />
          <section id="agenda" />
          {/* <Agenda /> */}
          <WhyAttend />
          <section id="sponsors" />
          <Sponsor sponsors={sponsorsPage} />
          <News news={news} />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
