import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Agenda from "components/Agenda";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";
import { sponsorsPage } from "./content";
// import Testimonials from "components/Testimonials.js";

function AgendaPage() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="AGENDA" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Agenda />
      </div>
      {/* <Testimonials /> */}
      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default AgendaPage;
