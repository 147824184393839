import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";

import { sponsorsPage } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                {(id === "REPORT" || id === "EBOOK") && (
                  <>
                    Thank you for your interest in Global Investment Forum. The
                    post-show report has been sent to the email address
                    provided.
                  </>
                )}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in Global Investment Forum 2021. The
                    event brochure has been sent to the email address provided,
                    please check your inbox.
                    <br />
                    In case you do not receive the brochure, please drop us a
                    line: events@khaleejtimes.com
                  </>
                )}
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest in Global Investment Forum 2021.
                    We’ll be in touch with you for further details about the
                    event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in Global Investment Forum 2021.
                    We’ll be in touch with you for further details about the
                    event.
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default ThankYou;
