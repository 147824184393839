import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Agenda from "components/Agenda";
import Live from "components/Live";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";
import { sponsorsPage, speakers } from "./content";
import Testimonials from "components/Testimonials.js";

function AgendaPage() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader
        title="UAE Israel Business Summit 2021"
        bgColor="#fff"
      />
      <Live speakers={speakers.filter((s, i) => [0, 1, 2, 5, 9].includes(i))} />
      {/* <div className="section profile-content" style={{ padding: 0 }}>
        <Agenda />
      </div> */}

      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default AgendaPage;
