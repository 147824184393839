import React from "react";
import { Container, Button, Row, Col, Card } from "reactstrap";

function Price() {
  //   const size =
  //     document.body.clientWidth >= 1024 ||
  //     document.documentElement.clientWidth >= 1024
  //       ? true
  //       : false;

  return (
    <>
      <div
        className="section pt-2"
        style={{
          backgroundColor: "rgb(234,208,241)",
          backgroundImage:
            "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        }}
        data-parallax={true}
      >
        <Container className="mt-0">
          <Row className="justify-content-center">
            <Col lg={12} className="align-self-center">
              <h1 className="section-title py-4 text-center text-dark">
                <b>PRICING OPTION </b>
              </h1>
            </Col>
            {content.map((c) => (
              <Col lg={4} key={c.name}>
                <Card className="p-3" style={{ minHeight: "380px" }}>
                  <Container className="my-auto">
                    <Row>
                      <Col
                        lg={12}
                        className="text-center"
                        style={{ borderBottom: "5px dotted #555555" }}
                      >
                        <h1 className="text-400">{c.name}</h1>
                        {c.delPrice && (
                          <h1 style={{ fontSize: "2rem" }} className="text-700">
                            <del>{c.delPrice}</del>
                          </h1>
                        )}
                        <h1
                          style={{ fontSize: "3rem" }}
                          className="text-700 text-danger"
                        >
                          {c.price}
                        </h1>
                        <h6 className="my-2 text-400">{c.text}</h6>
                      </Col>
                      <Col lg={12} className="pt-3">
                        <ul
                          className="text-left text-400"
                          style={{
                            listStyleImage:
                              "url(" +
                              require("assets/images/icons/37.png") +
                              ")",
                          }}
                        >
                          {c.points.map((p) => (
                            <li key={p}>{p}</li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                href=""
                className="btn mt-5"
                color="primary"
                size="lg"
              >
                <p className="m-0 text-700">SECURE PREMIUM PASS</p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Price;

const content = [
  {
    name: "",
    text: "(Not applicable to solution providers and consultants.)",
    delPrice: undefined,
    price: "FREE PLAN",
    points: [`Limited 2-day access during event only`],
  },
  {
    name: "PREMIUM PASS",
    text: "",
    delPrice: undefined,
    price: "AED 350",
    points: [
      `2-day online access`,
      `Unlimited Access ( Recorded Sessions)`,
      `Summary Notes/ PPT’s (PDF)`,
      `Certificate of Attendance`,
    ],
  },
];
