import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://uae-israel-business-summit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              <em>The Jerusalem Post</em>&nbsp;and&nbsp;<em>Khaleej Times</em>,
              the two largest English-language media organisations in Israel and
              the UAE, respectively, have partnered to present the first
              in-person conference in 2021 in Dubai.
            </p>
            <br />
            <br />
            <p>
              The partnership comes after the signing of the historic Abraham
              Accords between the UAE and Israel that ushered in a new vision
              for the Middle East.
            </p>

            <br />
            <br />
            <p>
              The UAE-Israel Peace &amp; Prosperity Roundtable will set the tone
              for the bilateral relations between the two countries. The
              half-day virtual confab will include renowned names from both
              sides spanning across government and business sectors discussing
              topics that form an integral part of the growth story of the UAE
              and Israel. Topics include healthcare technology, trade and
              investment, fintech collaboration and stability in the Middle
              East.
            </p>
            <br />
            <br />
            <p>
              Hundreds are expected to attend the in-person conference, which
              will take place in June 2021 in Dubai. It will be the first of
              its kind and bring together political figures and business and
              civic leaders to discuss the growing importance of both the UAE
              and Israel on a global scale. It will discuss the importance of
              the Abraham Accords that aims to bring long-lasting peace in the
              region.
            </p>
            <br />
            <br />
            <p>
              &ldquo;This is a new era for Israel, the UAE and the entire Middle
              East,&rdquo; said&nbsp;<em>The Jerusalem Post</em>
              &nbsp;Editor-in-Chief Yaakov Katz. &ldquo;We&rsquo;re excited to
              partner with the Khaleej Times to bring together the top thought
              leaders in this space to discuss opportunities for our two
              countries.&rdquo;
            </p>
            <br />
            <br />
            <p>
              Vaman Vassudev Kamat, Editor-in-Chief of&nbsp;
              <em>Khaleej Times</em>, said: &ldquo;We are moving towards an era
              of progress, prosperity, peace and friendship in the Middle East.
              The summit will help us explore business opportunities and
              strengthen people-to-people interactions.
            </p>
            <br />
            <br />
            <p>
              The UAE-Israel Business Council made the connection between the
              two media outlets.
            </p>
            <br />
            <br />
            <p>
              &ldquo;Our vision for the UAE-Israel Business Council is to create
              partnerships between Israeli and Emirati companies to benefit both
              societies,&rdquo; said UAE-Israel Business Council Co-founder and
              Deputy Mayor of Jerusalem Fleur Hassan-Nahoum. &ldquo;By bringing
              together these legacy media organisations from both countries,
              they have the opportunity to bring the top thought leaders in the
              new Middle East to create a conference that will set the stage for
              exciting opportunities and developments resulting from the Abraham
              Accords.&rdquo;
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
