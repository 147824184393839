import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardFooter from "reactstrap/lib/CardFooter";
import CardImg from "reactstrap/lib/CardImg";

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#f8f8f8",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-dark border-right">
              <h3 className="text-400">
                Global Investment Forum is located at Armani Hotel, Dubai, UAE.
                Armani is located in the world famous Burj Khalifa in Downtown
                Dubai. Please ensure you have taken the time to read all of the
                latest Government, Public Health and travel guidance. Please be
                aware that effective from 31st January 2021 all tourists and
                residents arriving into Dubai from any point of origin must hold
                a negative PCR test certificate, for which the test must be
                taken no more than 72hrs prior to the time of departure.
                Certificates must be printed in English or Arabic. For select
                destinations you may be required to take two PCR tests. Also
                there may be specific requirements for your departure. Please
                click below to find out more details and what is required for
                the country you are travelling from/to.
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={3}>
              <Button
                href="https://www.dubaitourism.gov.ae/en/legislative-news/covid-19-advisory"
                className="btn d-block py-2"
                color="primary"
                size="sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  COVID-19 Advisory
                </p>
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={5} className="text-dark">
              <img
                src={require(`assets/images/visa.jpg`)}
                width="100%"
                style={{ maxWidth: "450px" }}
                alt=""
              />
            </Col>
            <Col lg={7} className="text-dark">
              <h1 className="text-400">
                <b>Passports & Visas</b>
              </h1>
              <h3>
                A visa is required to enter the UAE except for Nationals
                (Citizens) from the following countries that will be issued
                entry permits upon arrival at the airport in the UAE. If you
                require a visa prior to your arrival, there are three options
                available: Expatriate residents from the gulf states (Kuwait,
                Qatar, Oman, Bahrain & Saudi Arabia) who are on the resident
                visa category of businessmen, company managers or
                representatives, auditors, accountants, doctors, engineers or
                employees working in the public sector can obtain a
                non-renewable 30-day visa upon arrival in UAE.
                <br />
                <ul>
                  <li>
                    <b>UAE Consulate in your Country</b>: We can issue a visa
                    invitation letter should you wish to apply through the UAE
                    consulate in your country. Please click here to facilitate
                    this.
                  </li>
                  <li>
                    <b>Hotel</b>: Request your hotel to arrange your visa. Not
                    all hotels offer this option, please check prior to booking.
                  </li>
                  <li>
                    <b>Airline</b>: if you travel by Emirates Airline, they will
                    be able to assist with your visa.
                  </li>
                </ul>
              </h3>
              <Button
                href="https://www.visitdubai.com/en/plan-your-trip/visa-information"
                className="btn  py-2 px-4"
                color="primary"
                size="sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  Dubai Visa & Entry Information
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#fff",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="text-g  section-title">
                <b>FAQs</b>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h1 className="text-400">
                <b>Are visas affected by coronavirus/COVID-19?</b>
              </h1>
              <h3>
                Please check your{" "}
                <a
                  href="https://gdrfad.gov.ae/en/form/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  visa requirements
                </a>{" "}
                and your country’s travel advisory before going on your trip
              </h3>
              <br />
              <h1 className="text-400">
                <b>Do I need a visa to enter Dubai?</b>
              </h1>
              <h3>
                Whether you need a visa or not is dependent on your country of
                citizenship. Most passport holders are eligible for a visa on
                arrival at no cost. However, others may need to apply for a visa
                beforehand. Select your nationality from the dropdown above to
                find out the visa requirements.
              </h3>
              <br />
              <h1 className="text-400">
                <b>How do I apply for a Dubai tourist visa?</b>
              </h1>
              <h3>
                If you require a visa to enter the UAE, you can apply via
                approved airlines, hotels, travel agencies or tour operators.
                You can also be sponsored by a UAE resident or company. Use the
                dropdown above to find out more
              </h3>
              <br />
              <h1 className="text-400">
                <b>How long can I stay in the UAE?</b>
              </h1>
              <h3>
                Your length of stay depends on your visa type starting from 14
                and up to 180 days.
              </h3>
              <br />
              <h1 className="text-400">
                <b>What is the validity period of my visa?</b>
              </h1>
              <h3>
                All visas are valid for 60 days from the date of issue until
                entry to the UAE, except the 48-hour and 96-hour visa Transit
                Visa (valid for 30 days)
              </h3>
              <br />
              <h1 className="text-400">
                <b>
                  What nationalities are entitled to a visa on arrival in Dubai?
                </b>
              </h1>
              <h3>
                Citizens of more than 50 countries do not require a pre-arranged
                visa to enter Dubai. Use the dropdown above to check the
                requirements for your nationality.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#f8f8f8",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={4}>
              <Card>
                <CardImg src={require(`assets/images/dubai.jpg`)} />
                <CardFooter>
                  <Button
                    href="https://www.visitdubai.com/en/things-to-do"
                    className="btn w-100  py-2 px-4"
                    color="primary"
                    size="sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="m-0 text-700" style={{ color: "#fff" }}>
                      Things to do in
                      <br /> Dubai
                    </p>
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardImg src={require(`assets/images/armani.jpg`)} />
                <CardFooter>
                  <Button
                    href="https://www.booking.com/searchresults.html?label=gen173nr-1FCAEoggI46AdIM1gEaAKIAQGYATG4ARfIAQzYAQHoAQH4AQKIAgGoAgO4ArDR5IQGwAIB0gIkNjk1NDYwN2ItYThlMi00ZWVkLWE2YjYtZWM2OGIyOGQ2ZGZm2AIF4AIB&sid=0bf3ee8f48a77a07c15e594f0f2920e5&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.html%3Flabel%3Dgen173nr-1FCAEoggI46AdIM1gEaAKIAQGYATG4ARfIAQzYAQHoAQH4AQKIAgGoAgO4ArDR5IQGwAIB0gIkNjk1NDYwN2ItYThlMi00ZWVkLWE2YjYtZWM2OGIyOGQ2ZGZm2AIF4AIB%3Bsid%3D0bf3ee8f48a77a07c15e594f0f2920e5%3Bsb_price_type%3Dtotal%26%3B&ss=Downtown+Dubai%2C+Dubai%2C+Dubai+Emirate%2C+United+Arab+Emirates&is_ski_area=&checkin_year=2021&checkin_month=5&checkin_monthday=31&checkout_year=2021&checkout_month=6&checkout_monthday=4&group_adults=1&group_children=0&no_rooms=1&map=1&b_h4u_keep_filters=&from_sf=1&ac_position=0&ac_langcode=en&ac_click_type=b&dest_id=1809&dest_type=district&place_id_lat=25.194888635&place_id_lon=55.275854333&search_pageview_id=96f358985186017b&search_selected=true&ss_raw=Downtown+Dubai&search_pageview_id=96f358985186017b&ac_suggestion_list_length=5&ac_suggestion_theme_list_length=0#map_closed"
                    className="btn w-100  py-2 px-4"
                    color="primary"
                    size="sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="m-0 text-700" style={{ color: "#fff" }}>
                      Hotels in the vicinity of
                      <br /> Armani Hotel Dubai
                    </p>
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <CardBody>
                  <h3 className="text-400">
                    Authorized Agent for Airline booking, hotel booking & visa’s
                  </h3>
                  <h2 className="text-700">
                    <b>Raja Mir Wasim</b>
                  </h2>
                  <h3>Manager ‑ MICE & Holidays</h3>
                  <h2>International Travel Services</h2>
                  <h3>
                    <a href="tel:+971 56 511 1700">+971 56 511 1700</a>
                    <br />
                    <a href="tel:+971 4 335 1135">+971 4 335 1135</a>
                    <br />
                    <a href="mailto:rwasim@galadaritravel.com">
                      rwasim@galadaritravel.com
                    </a>
                    <br />
                    <br />
                    P.O. Box 7283 , Block 1, Office Land Building, Karama,
                    Dubai, UAE
                  </h3>
                  <img
                    src={require(`assets/images/authvenue.jpg`)}
                    width="100%"
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    alt=""
                  />
                  <img
                    src={require(`assets/images/authvenue1.jpg`)}
                    width="100%"
                    style={{ maxWidth: "200px" }}
                    alt=""
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
