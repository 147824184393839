import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/images/background.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="">
                <h1 className="section-title text-center text-white">
                  <b>HEAR WHY INDUSTRY LEADERS PARTICIPATE</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <img
                src={require("assets/images/icons/quote.png")}
                style={{ maxWidth: "100px" }}
                alt="quote"
              />
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <br />
                    <br />
                    <h2
                      className="text-white text-600"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <br />
                    <h3 className="text-white text-800">{c.by}</h3>
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial: `
	I really enjoyed today’s panel discussion at Artelligence Forum Dubai, perfectly moderated
by Scott Nowson from PwC. <br/><br/>It was exciting to see such a huge degree of agreement based on
hands-on experience in sectors as diverse as Financial Services (Abhijit Akerkar), Insurance (Reza Khorshidi, D.Phil. (Oxon), Health Care (Richard Vdovjak) and Logistics (me)!
	`,
    by: "Martin Treder, MD -Data Management International – FedEx Express",
  },
  {
    testimonial: `
	Great, insightful and filled with passionate and visionary individuals under 1 platform. What more can you ask for with a well-organized 2-day event. The AI masterclass was an icing on the cake which makes this event stand out. Congratulations to Khaleej Times and the sponsors for the success. Looking forward to receiving the invite in the future for more conferences. I would recommend having this even twice in a year.
	`,
    by: "Fahd Maqba, Emirates Group (Forum Participant)",
  },
  {
    testimonial: `
	Absolutely exceeded my expectations - this was exactly the conference we need in the region. Just a start to the conversations we’re going to have.
	`,
    by: "Dr. Scott Nowson, Artificial Intelligence Lead – PwC Middle East",
  },
  {
    testimonial: `
	The sessions discussed the relationships among concepts relating to AI, Data Science and
Analytics, outline how organisations can empower the effective use of AI and Analytics, taking on the lens of people, process and technology as well as explore potential applications of AI and machine learning.
	`,
    by:
      "Jhonson Poh, Head of Data Science & Artificial Intelligence - United Overseas Bank, Singapore",
  },
];

export default Testimonials;
