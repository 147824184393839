import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";

import { sponsors } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({ answer }) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>Thank you for responding to our poll !</h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default ThankYou;
