import React from "react";

import { Container, Row, Col, Card, Button, CardImg } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhoAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space border-top border-bottom py-0 pb-3"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="align-self-center">
              <h2 className="text-left p-2 text-main">
                Attend the region’s most important gathering of AI experts
                dedicated to driving organisations towards measurable, scalable
                and explainable AI.
                <br />
                <br /> The comprehensive two-day conference comprises of both
                inspirational and hands-on content, as well as a virtual
                exhibition of emerging AI technologies, with an opportunity
                interact with AI experts, at the comfort of your own home.
              </h2>
            </Col>
            <Col lg={6} className="align-self-center">
              <img
                src={require("assets/images/attend.jpg")}
                alt="attend"
                width="100%"
              />
            </Col>
            <Col lg={12}>
              <Container fluid>
                <Row className="justify-content-center pt-3">
                  {content2.map((data, index) => (
                    <Col lg={6} key={index}>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}
                        duration={0.5}
                      >
                        <Card className="p-3">
                          <h3 className="text-700 pt-3">
                            <span className="text-success">
                              <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </span>{" "}
                            {data.title}
                          </h3>
                        </Card>
                      </ScrollAnimation>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{
            backgroundColor: "#a4508b",
            backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
          }}
        >
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                WHY VIRTUAL?
              </h1>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <Row className="justify-content-center">
            <Col lg={12} className="text-700">
              <h3>
                While we know there's no substitute for getting together in
                person, we've built a unique opportunity for collaboration and
                learning. We’ve tried to create a balance between the experience
                of an in-person event with the flexibility of location
                independence with the best-in-class virtual platform to ensure
                that you still experience the same high quality and content rich
                event that we have always produced - this is more than your
                standard webinar!
              </h3>
              <br />
              <h2>
                THE VIRTUAL PLATFORM WILL PROVIDE AN IMMERSIVE ONLINE EXPERIENCE
                FOCUSING ON:
              </h2>
            </Col>
            {content3.map((data, index) => (
              <Col lg={3} xs={6} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card className="p-3">
                    <CardImg
                      src={require(`assets/images/icons/${data.image}`)}
                      style={{ maxWidth: "100px" }}
                      className="mx-auto"
                    />
                    <hr />
                    <h3 className="text-700 pt-3">{data.title}</h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}

            <Col lg={12} className={"text-center"}>
              <Button
                href="/register"
                className="mt-4"
                color="primary"
                size="md"
              >
                REGISTER NOW
              </Button>
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-3"
          fluid
          style={{
            backgroundColor: "#a4508b",
            backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
          }}
        >
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                WHAT’S NEW FOR 2020?
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center my-5">
            {content.map((data, index) => (
              <Col
                lg={6}
                xs={12}
                key={index}
                className="py-2 text-center border"
              >
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <img
                    src={require(`assets/images/icons/${data.image}`)}
                    alt=""
                    width="100%"
                    style={{ maxWidth: "100px" }}
                  />
                  <h3 className="text-700 text-uppercase pt-3">{data.title}</h3>
                  <h3 className="text-400">{data.description}</h3>
                </ScrollAnimation>
              </Col>
            ))}
            <Col lg={12} className={"text-center"}>
              <Button
                href="/sponsor/download-brochure"
                className="mt-4"
                color="primary"
                size="md"
              >
                VIEW FULL AGENDA
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content2 = [
  {
    title: "Two full days of insights from an astounding lineup of AI experts",
  },
  {
    title:
      "Panel discussions to deliberate and drill down on specific concepts pertaining to AI",
  },
  {
    title:
      "Engaging certified workshops from the pioneers of AI for continuous learning and development",
  },
  {
    title:
      "Meaningful connections and interactions with peers, speakers and sponsors in a variety of unique ways including virtual chats, 1-1 video conferences, polls and more.",
  },
];

const content3 = [
  {
    title:
      "Insightful content with an opportunity to presentations live and engage in Q&A’s",
    image: "3.png",
  },
  {
    title:
      "Virtual chat rooms where registrants can talk to one another about session topics and connect directly with speakers",
    image: "8.png",
  },
  {
    title:
      "Dedicated video conferencing with small groups of like-minded peers on carefully curated topics",
    image: "38.png",
  },
  {
    title:
      "Virtual partners booths where you can pick up all the information you need to make an informed decision and also speak with a representative to hear all they have to offer.",
    image: "4.png",
  },
];

const content = [
  {
    title: "AI Best Use-Cases",
    image: "7.png",
    description:
      "Discussing real-world case studies of AI, machine learning, RPA implemented by industry giants and its key learnings to further simplify its adoption.",
  },
  {
    title: "CIO Boardrooms",
    image: "8.png",
    description:
      "Focused closed-door discussion among C-level decision-makers to discuss the current challenges and set the AI-powered future in motion. ",
  },
  {
    title: "Industry Debates",
    image: "39.png",
    description:
      "From ethical AI to data privacy regulations, this is where all the burning questions around AI are voiced openly to reach a higher level of resolve.",
  },
];
export default WhoAttend;
