import React from "react";
import { Container, Row, Col } from "reactstrap";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import ExamplesNavbar from "components/Navbar.js";
import Footer from "components/Footer.js";

import { sponsors } from "./content";
// import Sponsor from 'components/PastSponsor';

function SpeakerSingle(props) {
  const sponsorLink = props.match.params.sponsor;
  const sponsor = sponsors.find((x) => x.pageLink === sponsorLink);
  return (
    <>
      <ExamplesNavbar />
      <br />
      <ProfilePageHeader
        title={sponsor.alternateType ? sponsor.alternateType : sponsor.type}
      />

      <div
        className="section profile-content"
        style={{
          backgroundColor: "#eeeeee",
          maxWidth: "100%",
        }}
      >
        <Container>
          <div className="owner">
            <div
              className="avatar border"
              style={{
                backgroundColor: "#ffffff",
                maxWidth: "100%",
                marginTop: "2rem",
              }}
            >
              <a href={sponsor.link} target={"_blank"}>
                <img
                  alt={sponsor.name}
                  className="img-rounded img-no-padding img-responsive"
                  src={require(`assets/sponsors/${sponsor.image}`)}
                  style={{ maxWidth: "350px" }}
                />
              </a>
            </div>
            <div className="name">
              <h2
                className="title text-700 text-dark"
                style={{ marginBottom: "0" }}
              >
                {sponsor.name}
              </h2>
              <br />
            </div>
          </div>
          <Row>
            <Col className="text-dark" md="12">
              {sponsor.text.map((t, index) => (
                <p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>
              ))}
              <br />
              For more info visit:{" "}
              <a href={sponsor.link} target="_blank" rel="noopener noreferrer">
                {sponsor.link}
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}
export default SpeakerSingle;
