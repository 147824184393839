import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://uae-israel-business-summit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              John Rakolta, US Ambassador to the UAE, received the national
              security award from President Donald Trump on Wednesday for his
              role in a peace deal between the UAE and Israel.
            </p>
            <br />
            <br />
            <p>
              Rakolta was among the several top advisers who received awards for
              their role in helping broker agreements aimed at normalising
              relations between Israel and four Arab nations.
            </p>
            <br />
            <br />
            <p>
              Trump awarded the National Security Medal to Secretary of State
              Mike Pompeo, Treasury Secretary Steven Mnuchin, national security
              adviser Robert O&rsquo;Brien, senior adviser Jared Kushner, Middle
              East envoy Avi Berkowitz, US Ambassador to Israel David Friedman.
            </p>
            <br />
            <br />
            <p>
              &ldquo;Thanks to the efforts of these individuals, the region will
              never be the same as it finally moves beyond the conflicts of the
              past,&rdquo; the White House said in a statement.
            </p>
            <br />
            <br />
            <p>
              In the past four months, Israel reached deals with the UAE,
              Bahrain, Sudan and Morocco as part of what the US calls &ldquo;the
              Abraham Accords.&rdquo;
            </p>
            <br />
            <br />
            <p>
              Senior US officials are hoping to seal one more agreement before
              Trump&rsquo;s term expires on January 20.
            </p>

            <br />
            <br />
            <p>
              The president&rsquo;s authority to award the National Security
              Medal dates to 1953, under an executive order that allows for
              recognition for major contributions in the field of national
              security.
            </p>

            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
