import React from "react";
import {
  // Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Container className="p-4 border-bottom">
        <Row>
          <Col lg={2} className="align-self-center">
            <h3 className="text-400 mb-0 text-white">
              <b>{d.time}</b>
            </h3>
          </Col>
          <Col lg={10} className="align-self-center text-white">
            <h2 className="text-400 m-0">{d.title}</h2>
            {d.text &&
              d.text.map((t) => (
                <h3
                  className="text-400 m-0 mt-1"
                  dangerouslySetInnerHTML={{ __html: t }}
                />
              ))}
          </Col>
        </Row>
      </Container>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#051245",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={3}>
              <h1
                className="text-white section-title pt-3"
                style={{ borderTop: "5px solid #01b0ff" }}
              >
                <b>AGENDA</b>
              </h1>
              <h2 className="text-700 text-white">(Gulf Standard Time)</h2>
            </Col>
            <Col md="9">
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav tabs className="nav-fill">
                    <NavItem>
                      <NavLink
                        className={`text-left text-white p-0 py-3 ${
                          activeTab === "1" ? "active" : ""
                        }`}
                        onClick={() => {
                          toggle("1");
                        }}
                        // style={{ paddingRight: size && "2rem" }}
                      >
                        <h3 className="text-400">
                          <b>2nd June</b>
                        </h3>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={content1} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "08.00",
    title: "Registration and networking over coffee",
  },

  {
    time: "08:55",
    title: "Welcome and Introduction",
    text: [
      "Speakers ",
      "Yaakov Katz, Editor-in-Chief, Jerusalem Post",
      "Vinay Kamat, Editor-in-Chief, Khaleej Times",
    ],
  },

  {
    time: "09.00",
    title: "UAE Vision 2021",
    text: [
      `<p>The session will highlight the benefits of investing in the UAE due to growing industrial diversification, financial investment deregulations (residency and ownership measures), increase in free-trade zones available and much more.</p><br/>`,
      "His Excellency Dr. Thani bin Ahmed Al Zeyoudi Minister of State for Foreign Trade, Ministry of Economy UAE",
    ],
  },

  {
    time: "09.15",
    title: "Vision for Peace 2021",
    text: [
      "Ronald Lauder, President, World Jewish Congress & Chairman Emeritus of Estée Lauder Companies",
    ],
  },
  // {
  //   time: "09.25",
  //   title: "Seizing investment opportunities in Water Technology",
  //   text: ["Eli Cohen, CEO, Mekorot, National Water Company of Israel"],
  // },
  {
    time: "09.25",
    title: "Panel Discussion: Designing the Utilities of 2030 and Beyond ",
    text: [
      "Panelists:",
      "His Excellency Yousif Al Ali, Assistant Undersecretary for Electricity, Water and Future Energy Affairs",
      "Ehud (Udi) Adiri, Director-General, Energy Ministry",
      "Eli Cohen, CEO, Mekorot, National Water Company of Israel",
      "Moderator:",
      "Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },
  {
    time: "09:45",
    title:
      "Panel Discussion: Getting Technical: Energy & Water Technologies That Work",
    text: [
      "Panelists:",
      "Felix Moral, CEO, Rise Technologies/National Holding Company",
      "Moti Shiri, Vice President Engineering and Technology, Mekorot National Water Company of Israel",
      "Moderator:",
      "Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },

  {
    time: "09.55",
    title: "Expanding the public sector: Technology and Innovation",
    text: [
      "His Excellency Dr. Tariq Bin Hendi Director-General, Abu Dhabi Investment Office",
    ],
  },
  {
    time: "10.05",
    title: "International Money Flow – The Paradigm Shift",
    text: ["Mark Gazit, CEO, ThetaRay"],
  },
  {
    time: "10.10",
    title:
      "Panel Discussion: Fintech and the Future of Investing: Using the Right Tools at the Right Time",
    text: [
      "Panelists:",
      "Bruce Gurfein, CEO, Connect",
      "Sachhyam Regmi, Founding Partner, YAS Investments and Spartech Ventures	",
      "Mark Gazit, CEO, ThetaRay",
      "Dr Tillmann Lauk, Senior Advisor, Tactical Management Dubai",
      "Moderator:",
      "Abhishek Sengupta, Head of Multimedia, Khaleej Times",
    ],
  },
  {
    time: "10.30",
    title: "Cyberthreats in the Post-COVID Era",
    text: [
      "Gil Shwed, CEO, Check Point",
      "in conversation with Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },
  {
    time: "10:45",
    title: "Role of ECAs in Enabling Local Businesses to Expand Globally",
    text: [" Massimo Falcioni, CEO, Etihad Credit Insurance"],
  },
  {
    time: "11.00",
    title: "To the Moon and Back",
    text: [
      "Morris Kahn, Entrepreneur/ Philanthropist, Founder AMDOCS /SPACEIL",
      "in conversation with Steve Linde, Editor-in-Chief, The Jerusalem Report",
    ],
  },
  {
    time: "11.15",
    title:
      "Panel Discussion: Global Investment Outlook and Opportunities in the Aftermath  of the Pandemic ",
    text: [
      "Panelists:",
      "His Excellency Fahad Al Gergawi, CEO, Dubai Investment Development Agency",
      "Les Male, CEO, Dubai Gold & Commodities Exchange",
      "Vera Futorjanski, CEO & Founder, Veritas Ventures",
      "Daniel Seal, Founder & CEO, Unbound",

      "Moderator:",
      "Abhishek Sengupta, Head of Multimedia, Khaleej Times",
    ],
  },
  {
    time: "11.35",
    title: " Coffee and Networking Break ",
    text: [],
  },
  {
    time: "12.00",
    title:
      "Cash in on One of the Fastest Economic Growth Opportunities - India",
    text: [
      "His Excellency Pavan Kapoor, Indian Ambassador to the United Arab Emirates",
      "In conversation with Michal Divon, Senior Editor, Khaleej Times",
    ],
  },
  {
    time: "12.15",
    title: "Expediting Trade in Free zones and Urban Tech-Hubs",
    text: [
      "Ramy Jallad, CEO, Ras Al Khaimah Economic Zone",
      " in conversation with Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },
  {
    time: "12.30",
    title:
      "Panel Discussion: Co-investments in free zones, tourism and real estate",
    text: [
      "Panelists",
      "Ilanit Melchior, Director of Tourism, Jerusalem Development Authority",
      "Khalid Anib, CEO, Abu Dhabi National Hotels	",
      "Sheikha Abdulla AlNuaimi, Commercial Operations Executive Director, Ajman Free Zone ",

      "Moderator",
      "Steve Linde, Editor-in-Chief, The Jerusalem Report",
    ],
  },
  {
    time: "12.50",
    title: "Jerusalem as a Tourism Hub",
    text: [
      " Ilanit Melchior Director of Tourism, Jerusalem Development Authority",
    ],
  },
  {
    time: "12.55",
    title:
      "UAE-Israel Business Ties: How the Abraham Accords Created A Path to Peace and Prosperity",
    text: [
      "H.E. Dr. Dr. H.C. Raphael Nagel Founder and Chairman, The Abrahamic Business Circle",
      "in conversation with Yaakov Katz, Editor-in-Chief, The Jerusalem Post",
    ],
  },
  {
    time: "13:10",
    title:
      "Spotlight: Pluristem - Harnessing Cell Therapy Technology to Provide Next Generation Treatments",
    text: [
      "Yaky Yanay, CEO, Pluristem",
      "in conversation with Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },
  {
    time: "13.20",
    title:
      "Spotlight: Health Plus Middle East - Science and Medicine as a Pathway",
    text: [
      "Dr. Tatiana A. Zhelninova, Head, Health Plus Middle East",
      "in conversation with Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },
  {
    time: "13.30",
    title:
      "Spotlight: Immunai - A Health Technology that Could Change the World",
    text: [
      "Noam Solomon, CEO & Co-Founder, Immunai",
      "in conversation with Maayan Hoffman, Head of Strategy and Senior Analyst, The Jerusalem Post",
    ],
  },
  {
    time: "13.40",
    title: "Panel Discussion: Funding a Healthy Future ",
    text: [
      "Panelists:",
      "Prof. Rafael Beyar, Co-Founder & Managing General Partner, ALIVE Israel ",
      "Dr. Marwan Al Mulla, CEO, Health regulation Sector, Dubai Health Authority",

      "Moderator:",
      "Anjana Sarkar, Assistant Editor, Khaleej Times",
    ],
  },
  {
    time: "14.00",
    title: "A New Perspective in the Middle East",
    text: [
      "Eitan Naeh, Israeli Ambassador to the United Arab Emirates",
      "In conversation with Lahav Harkov, Diplomatic Correspondent, The Jerusalem Post ",
    ],
  },
  {
    time: "14.15",
    title: "New Real Estate Development Opportunities",
    text: ["Lee Ziv, Vice President, Port Tel Aviv Residence"],
  },

  {
    time: "14.20",
    title: "UAE Meets the Startup Nation",
    text: ["Jon Medved, CEO, OurCrowd"],
  },
  {
    time: "14.30",
    title: "Networking Lunch",
    text: ["Hosted by OurCrowd"],
  },

  {
    time: "15.40",
    title: "Panel Discussion: New Media in a New World",
    text: [
      "Panelists",
      "Regev Gur, Founder & CEO, Narrative Group ",
      "Natalie Milstein, Cross-border Business Specialist & VC Investment Analyst",
      "Tomer Shani, Co-Founder & CTO, Noveto",
      "Avi Bhojani, CEO, BPG Group ",

      "Moderator",
      "Lahav Harkov, Diplomatic Correspondent, The Jerusalem Post ",
    ],
  },
  {
    time: "16.00",
    title: "Panel Discussion: Morocco: A platform to investment in Africa",
    text: [
      "Panelists",
      "Mounssif Aderkaoui, Director of Financial Studies and Forecasting,  ",
      "Moroccan Ministry of Economy and Finance	",
      "Mehdi Tazi, Vice President, General Confederation of Moroccan Enterprises ",
      "Steve O’hana, President, Morocco-Israel Business Council",
      "Moderator",
      "Mohammed Zainabi, Editor-in-Chief, L'observature du Maroc",
    ],
  },
  {
    time: "16.20",
    title:
      "Panel Discussion: Creating cultural opportunities, exchange and connections based on  individual and shared histories",
    text: [
      "Panelists",
      "Paul Packer, Chairman of the US Commission for the Preservation of America’s Heritage Abroad ",
      "Dr. Ali Al Nuami, Member, UAE Federal National Council for the Emirate of Abu Dhabi & ",
      "Chairman, Defense Affairs, Interior & Foreign Affairs Committee",
      "Moderator",
      "Yaakov Katz, Editor-in-Chief, The Jerusalem Post ",
    ],
  },
  {
    time: "16.40",
    title: "Panel Discussion: Women in Diplomacy",
    text: [
      "Panelists:",
      "Marcy Grossman, Canadian Ambassador to the United Arab Emirates	",
      "Her Excellency Ambassador Houda Nonoo, Ambassador, Ministry of Foreign Affairs, Kingdom of Bahrain	",
      "Fleur Hassan Nahoum, Deputy Mayor of Jerusalem",
      "Ruth Wasserman Lande, CEO and Founder of Ruth Global Innovative Advisory",

      "Moderator:",
      "Ariella Steinreich, Senior Vice President and Head of the Middle East Division, Steinreich Communications",
    ],
  },

  {
    time: "17.00",
    title: "Visionary leadership in an evolving world ",
    text: [
      "Former Prime Minister of Israel Ehud Olmert",
      "Visionary leadership in an evolving world - in conversation with Yaakov Katz, Editor-in-Chief, The Jerusalem Post",
    ],
  },
];

export default Agenda;
