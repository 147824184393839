import React from "react";

function AnimeBackground() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const st = size
    ? {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: -100,
      }
    : { position: "absolute", top: 60, left: 0, width: "100%", zIndex: -100 };
  return (
    <div style={st}>
      <img
        src={require("assets/images/shapebg.png")}
        alt="bg-shape"
        width="100%"
      />
    </div>
  );
}

export default AnimeBackground;
